import React from "react";

import page1 from "../../../assets/tAndC1.png";
import page2 from "../../../assets/tAndC2.png";

import "./TermsAndConditions.css";

const TermsAndConditions = () => (
  <div className="terms-container">
    <img src={page1} alt="page1" />
    <img src={page2} alt="page2" />
  </div>
);

export default TermsAndConditions;
