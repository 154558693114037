import React from "react";
import { view } from "@risingstack/react-easy-state";

import { SHORT_INTERVAL_FREQUENCY } from "../../../../constants";

import Dropdown from "../../../Dropdown";
import PlaybackControls from "../../../PlaybackControls";
import CmtChart from "./CmtChart";
import EpsChart from "./EpsChart";
import FFHikeChart from "./FFHikeChart";
import IVolChart from "./IVolChart";
import PeChart from "./PeChart";
import TermStructureLegend from "./TermStructureLegend";

import * as requests from "../../../../actions/requests";

import "./TermStructureChartContainer.css";
import "../Information/Information.css";

function TermStructureChartContainer() {
  const chartOptions = [
    { text: "RTSpot CMT", value: "cmt" },
    { text: "RTSpot IVol", value: "ivol" },
    { text: "Total Market Earnings Projections", value: "eps" },
    { text: "Total Market P/E", value: "pe" },
    { text: "Federal Funds Rate Hike", value: "hike" },
  ];

  const [isPlaying, setIsPlaying] = React.useState(true);
  const [value, setValue] = React.useState(chartOptions[0].value);

  let changeChartInterval;

  React.useEffect(() => {
    requests.fetchIndexPoints();
  }, []);

  React.useEffect(() => {
    if (!isPlaying) {
      clearInterval(changeChartInterval);
      return;
    }

    changeChartInterval = setInterval(() => {
      forward();
    }, SHORT_INTERVAL_FREQUENCY);

    return () => {
      clearInterval(changeChartInterval);
    };
  });

  function backward() {
    const index = chartOptions.map(({ value }) => value).indexOf(value) - 1;

    if (index < 0) {
      setValue(chartOptions[chartOptions.length - 1].value);
    } else {
      setValue(chartOptions[index].value);
    }
  }

  function forward() {
    const index = chartOptions.map(({ value }) => value).indexOf(value) + 1;

    if (index >= chartOptions.length) {
      setValue(chartOptions[0].value);
    } else {
      setValue(chartOptions[index].value);
    }
  }

  const handleChange = (e) => setValue(e.target.value);
  const handlePlayPauseButtonClick = () => setIsPlaying(!isPlaying);

  return (
    <div className="term-structure-grid-container">
      <div className="term-structure-controls">
        <Dropdown>
          {{
            handleChange,
            value,
            options: chartOptions,
          }}
        </Dropdown>
        <PlaybackControls>
          {{
            handleBackButtonClick: backward,
            handleForwardButtonClick: forward,
            handlePlayPauseButtonClick,
            id: "term-structure-playback-controls",
            isPlaying,
          }}
        </PlaybackControls>
      </div>
      <TermStructureLegend />
      <div className="term-structure-charts-container">
        {value === "cmt" ? <CmtChart /> : null}
        {value === "eps" ? <EpsChart /> : null}
        {value === "ivol" ? <IVolChart /> : null}
        {value === "pe" ? <PeChart /> : null}
        {value === "hike" ? <FFHikeChart /> : null}
      </div>
    </div>
  );
  // }
}

export default view(TermStructureChartContainer);
