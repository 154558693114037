import React from "react";
import { view } from "@risingstack/react-easy-state";
import { v4 as uuidv4 } from "uuid";

import MANALoader from "../../../MANALoader";
import appStore from "../../../../stores/appStore";
import * as requests from "../../../../actions/requests";

import "./MicrostructureTable.css";

function generateContent(val) {
  return (
    <td key={uuidv4()}>
      <div
        style={{
          backgroundColor: `rgba(3, 169, 244, ${val / 100})`,
        }}
      >
        {val}
      </div>
    </td>
  );
}

function MicrostructureTable() {
  React.useEffect(() => {
    requests.fetchIndexesDispersionDailyData();
  }, []);

  const {
    microstructureTableData,
    microstructureTableHeaderRow,
    indexesMicrostructureDailyData,
  } = appStore;

  if (
    !microstructureTableData ||
    !microstructureTableHeaderRow ||
    !indexesMicrostructureDailyData
  ) {
    return <MANALoader />;
  }

  return (
    <table className="microstructure">
      <thead>
        <tr>
          {microstructureTableHeaderRow.map((header) => (
            <td key={uuidv4()}>{header}</td>
          ))}
        </tr>
      </thead>
      <tbody>
        {microstructureTableData.map((row) => {
          const rowKeys = Object.keys(row);

          return (
            <tr key={uuidv4()}>
              {rowKeys.map((key) => {
                return generateContent(row[key]);
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default view(MicrostructureTable);
