import React from "react";
import { view } from "@risingstack/react-easy-state";
import PropTypes from "prop-types";

import appStore from "../../stores/appStore";
import * as handlers from "../../actions/handlers";
import { manaPurple } from "../../colors.json";

import "./InfoPanelTrigger.css";

const InfoPanelTrigger = ({ id }) => {
  const { visibleInfoPanelIds } = appStore;
  const isOpen = visibleInfoPanelIds.includes(id);
  const style = { borderColor: isOpen ? "black" : manaPurple };

  return (
    <button
      className="info-panel-trigger"
      id={id}
      onClick={handlers.handleQuestionMarkClick}
      style={style}
    >
      <div>{isOpen ? "X" : "?"}</div>
    </button>
  );
};

export default view(InfoPanelTrigger);

InfoPanelTrigger.propTypes = {
  id: PropTypes.string.isRequired,
};
