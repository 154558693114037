import appStore from "../stores/appStore";
import * as intervals from "./intervals";
import * as utils from "./utils";

/**
 * Handles logout of google sign in for websites
 */
// export const handleLogout = ({ domainOutOfScope = false }) => {
//   const auth2 = window.gapi.auth2.getAuthInstance();
//   const { authenticatedUser } = appStore;

//   auth2.signOut().then(() => {
//     if (domainOutOfScope) {
//       appStore.authenticationMessage =
//         'Only MANA employees have access at this time. You must log out of other Google accounts before accessing MANA.';
//     } else {
//       appStore.authenticationMessage = `${authenticatedUser} signed out. You remain logged-in to your Google account.`;
//     }

//     appStore.authenticated = false;
//     appStore.authenticatedUser = null;
//   });
// };

/**
 * Handles the contact us menu item click
 */
export const handleContactUsMenuItemClick = () => {
  window.open("https://www.manatechllc.com/hire-us/", "_blank");
};

/**
 * Handles play/pause button click for bars/heat map
 */
export const handleIndexPlayPauseButtonClick = () => {
  intervals.toggleIndexSelectedBarInterval();
};

/**
 * Handles key down event for arrow right/left to cycle thru charts for Real Alpha Indexes
 */
export const handleDashboardKeyDown = (event) => {
  const { key } = event;

  const left = key === "ArrowLeft";
  const right = key === "ArrowRight";
  const space = key === " ";

  if (left) utils.changeSelectedBar({ prev: true });
  if (right) utils.changeSelectedBar({ next: true });
  if (space) handleIndexPlayPauseButtonClick();
  if (left || right) intervals.stopAndRestartSelectedBarInterval();
};

/**
 * Handles back button click for bars/heat map
 */
export const handleIndexBackButtonClick = () => {
  utils.changeSelectedBar({ prev: true });
  intervals.stopAndRestartSelectedBarInterval();
};

/**
 * Handles forward button click for bars/heat map
 */
export const handleIndexForwardButtonClick = () => {
  utils.changeSelectedBar({ next: true });
  intervals.stopAndRestartSelectedBarInterval();
};

/**
 * Handle clicking the bar chart labels and the bars themselves
 */
export const handleIndexYTickLabelClick = (e, value) => {
  intervals.stopAndRestartSelectedBarInterval();

  const index = utils.addIndexPrefix(value);

  utils.setNewCategory(value);

  appStore.selectedIndex = index;

  utils.setIndexBarChartsNextOrPreviousBarAndCategory({ next: true });
};

/**
 * Handles clicking on the tabs in the heatmap bar chart section
 */
export const handleHeatmapTabClick = (category) => {
  const index = appStore.indexNamesByCategory[category][0];
  const newSelectedIndex = utils.addIndexPrefix(index);

  intervals.stopAndRestartSelectedBarInterval();
  utils.setNewCategory(index);

  appStore.selectedIndex = newSelectedIndex;
};

/**
 * Handles click event on the question marks that are at the top right of each panel
 */
export const handleQuestionMarkClick = (e) => {
  const { id } = e.target;
  const { visibleInfoPanelIds } = appStore;

  let newVisibleInfoPanelIds;

  if (visibleInfoPanelIds.includes(id)) {
    newVisibleInfoPanelIds = visibleInfoPanelIds.filter((i) => i !== id);
  } else {
    newVisibleInfoPanelIds = visibleInfoPanelIds;
    newVisibleInfoPanelIds.push(id);
  }

  appStore.visibleInfoPanelIds = newVisibleInfoPanelIds;
};
