import React, { Component } from "react";
import { view } from "@risingstack/react-easy-state";

import IndexesOverview from "../IndexesOverview";

import appStore from "../../../../stores/appStore";
import * as intervals from "../../../../actions/intervals";

class RtSpotIndexes extends Component {
  componentDidMount() {
    intervals.startUpdateRtSpotIndexesDataInterval();
  }

  componentWillUnmount() {
    clearInterval(appStore.updateRtSpotIndexesDataInterval);
  }

  render() {
    const {
      activeRtSpotIndexes,
      rtSpotCurrentValues,
      rtSpotDailyData,
      rtSpotIntradayData,
      prefixes,
    } = appStore;

    return (
      <IndexesOverview
        currentValues={rtSpotCurrentValues}
        dailyData={rtSpotDailyData}
        intradayData={rtSpotIntradayData}
        headerTitle="RTSpot"
        indexes={activeRtSpotIndexes}
        replacer={prefixes.rtSpot}
      />
    );
  }
}

export default view(RtSpotIndexes);
