import React from "react";
import PropTypes from "prop-types";
import { view } from "@risingstack/react-easy-state";

import "./TargetPlot.css";

const TargetPlot = ({ style, value }) => (
  <div className="target-plot-container" title={value}>
    <div className="guideline" />
    <div className="zero-line" />
    {style.left && <div className="target" style={style} />}
  </div>
);

export default view(TargetPlot);

TargetPlot.propTypes = {
  style: PropTypes.shape({
    backgroundColor: PropTypes.string,
    left: PropTypes.number,
  }).isRequired,
  value: PropTypes.string.isRequired,
};
