import React from "react";
import PropTypes from "prop-types";
import { view } from "@risingstack/react-easy-state";
import classNames from "classnames";

import appStore from "../../stores/appStore";

import { handleHeatmapTabClick } from "../../actions/handlers";

import "./Tabs.css";

const Tabs = ({ activeTab }) => {
  const handleClick = ({ target }) => {
    const { indexHeatmapBarChartCurrentCategory } = appStore;
    const { category } = target.dataset;

    if (category !== indexHeatmapBarChartCurrentCategory) {
      handleHeatmapTabClick(category);
    }
  };

  if (!appStore.indexBarChartValuesByCategory)
    return <section className="tabs" />;

  const quantamentalsIsActive = { active: activeTab === "quantamentals" };
  const meanReversionIsActive = { active: activeTab === "meanReversion" };
  const riskIsActive = { active: activeTab === "risk" };

  const quantamentalsTabClasses = classNames(quantamentalsIsActive);
  const meanReversionTabClasses = classNames(meanReversionIsActive);
  const riskTabClasses = classNames(riskIsActive);

  return (
    <div className="tabs">
      <div
        className={quantamentalsTabClasses}
        data-category="quantamentals"
        onClick={handleClick}
      >
        QUANTAMENTALS
      </div>
      <div
        className={meanReversionTabClasses}
        data-category="meanReversion"
        onClick={handleClick}
      >
        Mean Reversion
      </div>
      <div
        className={riskTabClasses}
        data-category="risk"
        onClick={handleClick}
      >
        Risk
      </div>
    </div>
  );
};

export default view(Tabs);

Tabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
};
