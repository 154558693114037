export const API_HOST = (() => {
  let apiHostname = process.env.API_HOSTNAME;

  // If --env.apiHostname was NOT specified in the command line...
  if (!apiHostname) {
    if (process.env.NODE_ENV === "production") {
      // Production environment should automatically point to production api host.
      apiHostname = "api.mana-research.com";
    } else {
      // Otherwise, point to development api host.
      apiHostname = "api.mana-research.com";
    }
  }

  return `${apiHostname}`;
})();

export const URL_BASE = `https://${API_HOST}`;

export const LONG_INTERVAL_FREQUENCY = 5 * 60 * 1000; // Five minutes
export const SHORT_INTERVAL_FREQUENCY = 10 * 1000; // Ten seconds

export const GOOGLE_BUTTON_ID = "google-sign-in-button";

export const yyyyMMdd = "yyyyMMdd";
export const MMM_dd_YYYY = "MMM dd, yyyy";
