var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// @ts-nocheck
import { scaleLinear } from "@vx/scale";
import { addDays, addMinutes, closestIndexTo, format, isAfter, isBefore, parse, subQuarters, subYears, endOfQuarter, } from "date-fns";
import { yyyyMMdd } from "../constants";
import { mutedGreen, mutedRed } from "../colors.json";
import appStore from "../stores/appStore";
import * as intervals from "./intervals";
import * as requests from "./requests";
/**
 * Returns the category of the supplied index
 * @param index The index whose category you want to find
 */
export var getCategoryFromTrimmedIndexName = function (index) {
    var indexNamesByCategory = appStore.indexNamesByCategory;
    var categories = Object.keys(indexNamesByCategory);
    var theCategory = '';
    categories.forEach(function (category) {
        if (indexNamesByCategory[category].includes(index))
            theCategory = category;
    });
    return theCategory;
};
/**
 * Removes index prefix
 * @param index The index to remove the prefix from
 */
export var removeIndexPrefix = function (index) {
    var _a = appStore.prefixes, microstructure = _a.microstructure, mc = _a.mc, earnings = _a.earnings, edgar = _a.edgar, realAlpha = _a.realAlpha, risk = _a.risk;
    var unprefixedIndex = index;
    if (index.startsWith(microstructure)) {
        unprefixedIndex = index.replace(microstructure, "");
    }
    else if (index.startsWith(mc)) {
        unprefixedIndex = index.replace(mc, "");
    }
    else if (index.startsWith(realAlpha)) {
        unprefixedIndex = index.replace(realAlpha, "");
    }
    else if (index.startsWith(risk)) {
        unprefixedIndex = index.replace(risk, "");
    }
    else if (index.startsWith(earnings)) {
        unprefixedIndex = index.replace(earnings, "");
    }
    else if (index.startsWith(edgar)) {
        unprefixedIndex = index.replace(edgar, "");
    }
    return unprefixedIndex;
};
/**
 * Sets the next category and bar to be selected to the next or previous category/bar combination
 * @param nextOrPrev determines direction 'next' or 'prev
 */
export var setIndexBarChartsNextOrPreviousBarAndCategory = function (nextOrPrev) {
    var indexBarChartValuesByCategory = appStore.indexBarChartValuesByCategory, indexNamesByCategory = appStore.indexNamesByCategory, selectedIndex = appStore.selectedIndex;
    var currentCategory = appStore.indexHeatmapBarChartCurrentCategory;
    var categories = Object.keys(indexBarChartValuesByCategory);
    var categoryIndex = categories.indexOf(currentCategory);
    var trimmedSelected = removeIndexPrefix(selectedIndex);
    var barIndex = indexNamesByCategory[currentCategory].indexOf(trimmedSelected);
    var barLimit = indexNamesByCategory[currentCategory].length;
    var categoryLimit = categories.length;
    var nextBarIndex = 0;
    var nextCategoryIndex = categoryIndex;
    if (nextOrPrev === 'next') {
        var barIndexGreaterThanLimit = barIndex + 1 >= barLimit;
        nextBarIndex = barIndexGreaterThanLimit ? 0 : barIndex + 1;
        if (barIndexGreaterThanLimit) {
            nextCategoryIndex =
                categoryIndex + 1 >= categoryLimit ? 0 : categoryIndex + 1;
        }
    }
    else if (nextOrPrev === 'prev') {
        var negativeBarIndex = barIndex - 1 === -1;
        if (negativeBarIndex) {
            nextCategoryIndex =
                categoryIndex - 1 === -1 ? categoryLimit - 1 : categoryIndex - 1;
            barLimit = indexNamesByCategory[categories[nextCategoryIndex]].length;
        }
        nextBarIndex = negativeBarIndex ? barLimit - 1 : barIndex - 1;
    }
    appStore.indexBarChartsNextBarIndex = nextBarIndex;
    appStore.indexBarChartsNextCategory = categories[nextCategoryIndex];
};
/**
 * Wraps a selectedIndex with text to make it the Fully Qualified Name
 * e.g. MANA Equity Market Neutral GoldRisk Index
 * @param selectedIndex The index for whom to get the fully qualified name
 */
export var getFullyQualifiedName = function (selectedIndex) {
    var index = removeIndexPrefix(selectedIndex);
    var risk = getCategoryFromTrimmedIndexName(index) === "risk";
    var fqn = "MANA " + (risk ? "Equity Market Neutral" : "") + " " + index + " Index";
    return fqn;
};
/**
 * Sets indexHeatmapBarChartCurrentCategory to the category of the value that was selected in the bar chart
 * @param value The value that was selected
 */
export var setNewCategory = function (value) {
    appStore.indexHeatmapBarChartCurrentCategory = getCategoryFromTrimmedIndexName(value);
};
/**
 * Prepend the index name with the index prefix
 * @param index The index to prefix
 */
export var addIndexPrefix = function (index) {
    var prefixes = appStore.prefixes;
    var _a = appStore.indexNamesByCategory, meanReversion = _a.meanReversion, quantamentals = _a.quantamentals, risk = _a.risk;
    var swappedIndexName = index;
    quantamentals.forEach(function (mcIndex) {
        if (index.endsWith(mcIndex))
            swappedIndexName = "" + prefixes.mc + index;
    });
    meanReversion.forEach(function (mrIndex) {
        if (index.endsWith(mrIndex))
            swappedIndexName = "" + prefixes.realAlpha + index;
    });
    risk.forEach(function (riskIndex) {
        if (index.endsWith(riskIndex))
            swappedIndexName = "" + prefixes.risk + index;
    });
    return swappedIndexName;
};
/**
 * Changes the currently selected bar and its related charts to the next/prev one
 */
var changeCurrentlySelectedBar = function () { return __awaiter(void 0, void 0, void 0, function () {
    var indexBarChartsNextBarIndex, indexBarChartsNextCategory, indexNamesByCategory, newselectedIndex, indexPrefixTypeAndIndex;
    return __generator(this, function (_a) {
        indexBarChartsNextBarIndex = appStore.indexBarChartsNextBarIndex, indexBarChartsNextCategory = appStore.indexBarChartsNextCategory, indexNamesByCategory = appStore.indexNamesByCategory;
        newselectedIndex = indexNamesByCategory[indexBarChartsNextCategory][indexBarChartsNextBarIndex];
        indexPrefixTypeAndIndex = addIndexPrefix(newselectedIndex);
        appStore.indexHeatmapBarChartCurrentCategory = indexBarChartsNextCategory;
        appStore.selectedIndex = indexPrefixTypeAndIndex;
        return [2 /*return*/];
    });
}); };
/**
 * Get the earnings and edgar indexes names into array
 * @param type The type of earnings index - either 'edgar' or 'earnings'
 */
export var getEarningsIndexesNamesArray = function (type) {
    var prefix = appStore.prefixes[type];
    return [prefix + "start", prefix + "dn", prefix + "up"];
};
/**
 * Interval function for stepping thru the bars in the pure alpha indexes bar chart
 * As it steps thru the bars, the YTD and 10Yr charts below change to the selection
 * @param nextOrPrev determines direction 'next' or 'prev
 */
export var changeSelectedBar = function (nextOrPrev) {
    setIndexBarChartsNextOrPreviousBarAndCategory(nextOrPrev);
    changeCurrentlySelectedBar();
};
/**
 * Gets the index of the supplied date from 12 months prior
 * @param date The date to search for in the supplied 'dates' array
 * @param dates The array of dates to search in
 */
export var getIndexOfTwelveMonthsAgo = function (date, dates) {
    var parsed = parse(date, yyyyMMdd, new Date());
    var parsedYear = subYears(parsed, 1);
    var parsedDay = addDays(parsedYear, 1);
    var twelveMonthsAgo = format(parsedDay, yyyyMMdd);
    var indexOfTwelveMonthsAgo = dates.indexOf(twelveMonthsAgo);
    if (indexOfTwelveMonthsAgo === -1) {
        indexOfTwelveMonthsAgo = closestIndexTo(parse(twelveMonthsAgo, yyyyMMdd, new Date()), dates.map(function (date) { return parse(date, yyyyMMdd, new Date()); }));
    }
    return indexOfTwelveMonthsAgo;
};
/**
 * Returns the index of the past 8 quarters from the dates passed in
 * Current quarter-to-date and 7 quarters prior to the quarter which today's date is in
 * @param dates the array of dates to search within
 */
export var getStartIndexOfPastEightQuarters = function (dates) {
    var latestDate = parse(dates[dates.length - 1], yyyyMMdd, new Date());
    var sevenQuartersAgo = endOfQuarter(subQuarters(latestDate, 8));
    var indexOfSevenQuartersAgo = closestIndexTo(sevenQuartersAgo, dates.map(function (date) { return parse(date, yyyyMMdd, new Date()); }));
    return indexOfSevenQuartersAgo;
};
/**
 * Gets the average value from array of values
 * @param array the values to compute on
 */
export var getAverageFromArray = function (array) {
    var length = array.length;
    var total = 0;
    for (var i = 0; i < length; i++) {
        total += array[i];
    }
    var average = total / length;
    return average;
};
/**
 * Gets the start and end indexes of the supplied params
 */
export var getAverageReturnFromDatesArray = function (ret, dates, years) {
    var yearDates = [];
    years.forEach(function (year) {
        dates.forEach(function (date) {
            if (date.startsWith(year))
                yearDates.push(date);
        });
    });
    var endIndex = dates.indexOf(yearDates[yearDates.length - 1]);
    var startIndex = dates.indexOf(yearDates[0]);
    var theArray = [];
    ret.forEach(function (item, index) {
        if (index >= startIndex && index <= endIndex)
            theArray.push(item);
    });
    return getAverageFromArray(theArray).toFixed(1);
};
/**
 * Returns the min, avg and max values from supplied array
 * @param data array to compute on
 */
var getMinAvgMax = function (data) {
    var min = Math.min.apply(Math, data);
    var max = Math.max.apply(Math, data);
    var avg = (min + max) / 2;
    return { min: min, avg: avg, max: max };
};
/**
 * Set heatmap scales
 * Last month and mtd periods share a single scale
 * All, ytd and sharpe each have separate scales
 */
export var setHeatmapScales = function () {
    var _a = appStore.heatmapValuesByCategory.meanReversion, allMR = _a.all, ytdMR = _a.ytd, mtdMR = _a.mtd, sharpeMR = _a.sharpe;
    var _b = appStore.heatmapValuesByCategory.risk, allR = _b.all, ytdR = _b.ytd, mtdR = _b.mtd, sharpeR = _b.sharpe;
    var _c = appStore.heatmapValuesByCategory.quantamentals, allQ = _c.all, ytdQ = _c.ytd, mtdQ = _c.mtd, sharpeQ = _c.sharpe;
    var sharpeWithoutNanMR = sharpeMR.filter(function (val) { return val !== "NaN"; });
    var sharpeWithoutNanR = sharpeR.filter(function (val) { return val !== "NaN"; });
    var sharpeWithoutNanQ = sharpeQ.filter(function (val) { return val !== "NaN"; });
    var mutedYellow = "#FEDD88";
    var range = [mutedRed, mutedYellow, mutedGreen];
    var _d = getMinAvgMax(allMR), allMinMR = _d.min, allAvgMR = _d.avg, allMaxMR = _d.max;
    var _e = getMinAvgMax(allR), allMinR = _e.min, allAvgR = _e.avg, allMaxR = _e.max;
    var _f = getMinAvgMax(allQ), allMinQ = _f.min, allAvgQ = _f.avg, allMaxQ = _f.max;
    var _g = getMinAvgMax(ytdMR), ytdMinMR = _g.min, ytdAvgMR = _g.avg, ytdMaxMR = _g.max;
    var _h = getMinAvgMax(ytdR), ytdMinR = _h.min, ytdAvgR = _h.avg, ytdMaxR = _h.max;
    var _j = getMinAvgMax(ytdQ), ytdMinQ = _j.min, ytdAvgQ = _j.avg, ytdMaxQ = _j.max;
    var _k = getMinAvgMax(mtdMR), mtdMinMR = _k.min, mtdAvgMR = _k.avg, mtdMaxMR = _k.max;
    var _l = getMinAvgMax(mtdR), mtdMinR = _l.min, mtdAvgR = _l.avg, mtdMaxR = _l.max;
    var _m = getMinAvgMax(mtdQ), mtdMinQ = _m.min, mtdAvgQ = _m.avg, mtdMaxQ = _m.max;
    var _o = getMinAvgMax(sharpeWithoutNanMR), sharpeMinMR = _o.min, sharpeAvgMR = _o.avg, sharpeMaxMR = _o.max;
    var _p = getMinAvgMax(sharpeWithoutNanR), sharpeMinR = _p.min, sharpeAvgR = _p.avg, sharpeMaxR = _p.max;
    var _q = getMinAvgMax(sharpeWithoutNanQ), sharpeMinQ = _q.min, sharpeAvgQ = _q.avg, sharpeMaxQ = _q.max;
    var allScaleMR = scaleLinear({
        domain: [allMinMR, allAvgMR, allMaxMR],
        range: range,
    });
    var allScaleR = scaleLinear({
        domain: [allMinR, allAvgR, allMaxR],
        range: range,
    });
    var allScaleQ = scaleLinear({
        domain: [allMinQ, allAvgQ, allMaxQ],
        range: range,
    });
    var ytdScaleMR = scaleLinear({
        domain: [ytdMinMR, ytdAvgMR, ytdMaxMR],
        range: range,
    });
    var ytdScaleR = scaleLinear({
        domain: [ytdMinR, ytdAvgR, ytdMaxR],
        range: range,
    });
    var ytdScaleQ = scaleLinear({
        domain: [ytdMinQ, ytdAvgQ, ytdMaxQ],
        range: range,
    });
    var mtdScaleMR = scaleLinear({
        domain: [mtdMinMR, mtdAvgMR, mtdMaxMR],
        range: range,
    });
    var mtdScaleR = scaleLinear({
        domain: [mtdMinR, mtdAvgR, mtdMaxR],
        range: range,
    });
    var mtdScaleQ = scaleLinear({
        domain: [mtdMinQ, mtdAvgQ, mtdMaxQ],
        range: range,
    });
    var sharpeScaleMR = scaleLinear({
        domain: [sharpeMinMR, sharpeAvgMR, sharpeMaxMR],
        range: range,
    });
    var sharpeScaleR = scaleLinear({
        domain: [sharpeMinR, sharpeAvgR, sharpeMaxR],
        range: range,
    });
    var sharpeScaleQ = scaleLinear({
        domain: [sharpeMinQ, sharpeAvgQ, sharpeMaxQ],
        range: range,
    });
    appStore.heatmapScalesByCategory = {
        meanReversion: {
            allScale: allScaleMR,
            ytdScale: ytdScaleMR,
            mtdScale: mtdScaleMR,
            sharpeScale: sharpeScaleMR,
        },
        risk: {
            allScale: allScaleR,
            ytdScale: ytdScaleR,
            mtdScale: mtdScaleR,
            sharpeScale: sharpeScaleR,
        },
        quantamentals: {
            allScale: allScaleQ,
            ytdScale: ytdScaleQ,
            mtdScale: mtdScaleQ,
            sharpeScale: sharpeScaleQ,
        },
    };
};
var marketHolidays = {
    closures: [
        20200907,
        20201126,
        20201225,
        20210101,
        20210118,
        20210215,
        20210402,
        20210531,
        20210705,
        20210906,
        20211125,
        20211224,
        20220117,
        20220221,
        20220415,
        20220530,
        20220704,
        20220905,
        20221124,
        20221226,
    ],
    early: [20201127, 20201224, 20211126, 20221125],
};
/**
 * Returns current time in four digit format
 */
function getFormattedTime(now) {
    return format(now, "HHmm");
}
/**
 * Returns current date in eight digit format
 */
function getFormattedDate(now) {
    return format(now, yyyyMMdd);
}
/**
 * Sets whether the market is open
 */
export function setMarketIsOpen() {
    var now = new Date();
    var date = getFormattedDate(now);
    var time = getFormattedTime(now);
    var closures = marketHolidays.closures, early = marketHolidays.early;
    var close = early.includes(date) ? 1259 : 1559;
    if (time < 930 || time > close || closures.includes(date)) {
        appStore.marketIsOpen = false;
    }
    else {
        appStore.marketIsOpen = true;
    }
}
/**
 * Check if daily and intraday chart data needs updating
 * based on whether the data is more than five minutes old
 */
export var chartDataNeedsUpdate = function () {
    var chartDataTimestamps = appStore.chartDataTimestamps, selectedIndex = appStore.selectedIndex;
    var chartUpdated = chartDataTimestamps[selectedIndex];
    var chartUpdatedFuture = addMinutes(chartUpdated, 5);
    var needsUpdate = isBefore(chartUpdatedFuture, new Date());
    return needsUpdate;
};
/**
 * Get the status of intraday data
 * Due to the way we collect the data, it is not stable until 945
 */
export var getIntradayDataIsReady = function () {
    var marketOpen = new Date();
    marketOpen.setHours(9);
    marketOpen.setMinutes(44);
    marketOpen.setSeconds(59);
    appStore.intradayDataIsReady = isAfter(new Date(), marketOpen);
};
/**
 * Get intraday data from 9:45 a.m.
 */
export var getIntradayDataFrom945 = function (data) {
    return data.dates.reduce(function (acc, date, ix) {
        var now = new Date();
        var dateSplit = date.split(":");
        var hour = +dateSplit[0];
        var minute = +dateSplit[1];
        now.setHours(hour);
        now.setMinutes(minute);
        now.setSeconds(0);
        if (hour > 9 || (hour === 9 && minute > 44)) {
            acc.push({
                date: now,
                value: data.ret[ix],
            });
        }
        return acc;
    }, []);
};
/**
 * Gets the chartData indexes of the shaded areas in shaded line charts
 */
export var getShadedAreasIndexes = function (dates) {
    var initialDate = dates[0];
    var shadedAreasIndexes = [0];
    dates.forEach(function (date, index) {
        if (date !== initialDate) {
            shadedAreasIndexes.push(index);
            initialDate = date;
        }
    });
    return shadedAreasIndexes;
};
/**
 * Updates data
 */
export var updateData = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, requests.fetchIndexList()];
            case 1:
                _a.sent();
                return [4 /*yield*/, requests.fetchIndexValues()];
            case 2:
                _a.sent();
                return [4 /*yield*/, requests.fetchIndexPoints()];
            case 3:
                _a.sent();
                return [4 /*yield*/, requests.fetchIndexesDispersionDailyData()];
            case 4:
                _a.sent();
                if (!appStore.paint)
                    intervals.startChangeSelectedBarInterval();
                return [2 /*return*/];
        }
    });
}); };
/**
 * Updates industries indexes data
 */
export var updateIndustriesIndexesData = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, requests.fetchIndexesOverviewData("industries")];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
/**
 * Updates rt spot indexes data
 */
export var updateRtSpotIndexesData = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, requests.fetchIndexesOverviewData("rtSpot")];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
/**
 * Generic fetch helper
 */
export var fetchData = function (url) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetch(url)];
            case 1:
                response = _a.sent();
                if (!response.ok) {
                    console.error(response);
                    return [2 /*return*/, null];
                }
                return [2 /*return*/, response.json()];
        }
    });
}); };
