import React from "react";
import { view } from "@risingstack/react-easy-state";
import { mutedGreen, darkGray } from "../../colors.json";
import { setMarketIsOpen } from "../../actions/utils";
import appStore from "../../stores/appStore";

import "./MarketStatus.css";

function MarketStatus() {
  const { marketIsOpen } = appStore;

  React.useEffect(() => {
    setMarketIsOpen();

    const setMarketIsOpenInterval = setInterval(() => {
      setMarketIsOpen();
    }, 500);

    return () => clearInterval(setMarketIsOpenInterval);
  }, [marketIsOpen]);

  return (
    <div
      className="status"
      style={{ color: marketIsOpen ? mutedGreen : darkGray }}
    >
      U.S. Markets are {marketIsOpen ? "open" : "closed"}
    </div>
  );
}

export default view(MarketStatus);
