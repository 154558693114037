import React, { Component } from "react";
import { view } from "@risingstack/react-easy-state";

import MANALoader from "../../../MANALoader";
import ShadedLineChart from "../../../ShadedLineChart";
import ShadedLineChartLegend from "../../../ShadedLineChartLegend";
import { URL_BASE } from "../../../../constants";

import appStore from "../../../../stores/appStore";
import * as processors from "../../../../actions/processors";
import * as requests from "../../../../actions/requests";
import * as utils from "../../../../actions/utils";

class AllDataChart extends Component {
  async componentDidMount() {
    const index = "Risk_LiquidUSRisk";
    const allData = await utils.fetchData(`${URL_BASE}/indexes/${index}/daily`);

    processors.mungeIndexDaily(allData, index);

    await requests.fetchIndexDaily();
  }

  componentDidUpdate() {
    const { allChartData, selectedIndex } = appStore;
    const chartData = allChartData[selectedIndex];
    const needsUpdate = utils.chartDataNeedsUpdate();

    if (!chartData || needsUpdate) requests.fetchIndexDaily();
  }

  render() {
    const { allChartData, selectedIndex } = appStore;

    const chartData = allChartData[selectedIndex];
    const riskData = allChartData.Risk_LiquidUSRisk;

    if (!chartData || !riskData) return <MANALoader />;

    const margin = {
      left: 24,
      right: 50,
      top: 14,
      bottom: 14,
    };

    const dates = chartData.map(({ date }) => {
      const year = +date.substring(0, 4);
      const month = +date.substring(4, 6) - 1;
      const day = +date.substring(6);
      const theDate = new Date(year, month, day);

      return theDate.getFullYear();
    });
    const shadedAreasIndexes = utils.getShadedAreasIndexes(dates);
    const yTickFormatter = (tick) => `${(+tick * 100).toFixed(1)}%`;

    return (
      <>
        <ShadedLineChartLegend>
          {{
            chart: "all",
            title: "Life-to-date",
          }}
        </ShadedLineChartLegend>
        <div style={{ height: "calc(100% - 18px)" }}>
          <ShadedLineChart
            chartData={chartData}
            riskData={riskData}
            margin={margin}
            shadedAreasIndexes={shadedAreasIndexes}
            xTickFormat={{
              exclude: [],
              prefix: "'",
              dateFormat: "yy",
              suffix: "",
            }}
            yTickFormatter={yTickFormatter}
          />
        </div>
      </>
    );
  }
}

export default view(AllDataChart);
