import React from "react";
import { view } from "@risingstack/react-easy-state";

import appStore from "../../../../stores/appStore";

import Block from "../../../Block";
import MANALoader from "../../../MANALoader";
import TermStructureChart from "./TermStructureChart";

const CmtChart = () => {
  const { cmtChartData } = appStore.termStructureChartData;

  if (!cmtChartData) return <MANALoader />;

  const margin = {
    left: 22,
    right: 0,
    top: 20,
    bottom: 24,
  };

  return (
    <Block>
      {{
        basic: true,
        classes: "chart-container",
        content: (
          <TermStructureChart chartData={cmtChartData} margin={margin} />
        ),
      }}
    </Block>
  );
};

export default view(CmtChart);
