import { closestIndexTo, format, parse, subMonths } from "date-fns";

import { yyyyMMdd, MMM_dd_YYYY } from "../constants";
import appStore from "../stores/appStore";
import * as utils from "./utils";

/**
 * Munge intraday chart data
 */
export const mungeIndexIntraday = (data, index) => {
  const chartData = utils.getIntradayDataFrom945(data);
  const intradayDayFormatted = format(new Date(data.ts * 1000), "MMM dd, yyyy");

  let intradayStart;
  let intradayEnd;
  let intradayForDisplay = intradayDayFormatted;

  if (chartData.length > 0) {
    intradayStart = format(chartData[0].date, "H:mm");
    intradayEnd = format(chartData[chartData.length - 1].date, "H:mm");
    intradayForDisplay = `${intradayDayFormatted} ${intradayStart} - ${intradayEnd}`;
  }

  appStore.chartDates.intraday = intradayForDisplay;
  appStore.intradayChartData[index] = chartData;
  appStore.intradayChartDataUpdated = new Date();

  return null;
};

/**
 * Munge all chart data
 */
export const mungeIndexDaily = (allData, index) => {
  const allChartData = [];
  const { dates } = allData;
  const endDate = dates[dates.length - 1];

  const indexOfTwelveMonthsAgo = utils.getIndexOfTwelveMonthsAgo(
    endDate,
    dates
  );

  let beyondInitialZeros = false;

  dates.forEach((date, ix) => {
    const value = allData.ret[ix];
    const nextValue = allData.ret[ix + 1];
    const item = { date, value };

    if (!beyondInitialZeros && nextValue !== 0) beyondInitialZeros = true;

    if (!beyondInitialZeros) {
      allChartData.push({
        ...item,
        value: NaN,
      });
    } else {
      allChartData.push(item);
    }
  });

  const tempTrailing12ChartData = allChartData.slice(indexOfTwelveMonthsAgo);
  const baseValue = tempTrailing12ChartData[0].value;
  const trailing12ChartData = tempTrailing12ChartData.map((data, ix) => {
    const value = ix === 0 ? 0 : data.value - baseValue;

    return { date: data.date, value };
  });


  const allStart = parse(allChartData[0].date, yyyyMMdd, new Date());
  const allStartFormatted = format(allStart, MMM_dd_YYYY);
  const allEnd = format(parse(endDate, yyyyMMdd, new Date()), MMM_dd_YYYY);
  const trailing12Start = parse(
    trailing12ChartData[0].date,
    yyyyMMdd,
    new Date()
  );
  const trailing12StartFormatted = format(trailing12Start, MMM_dd_YYYY);

  appStore.chartDates.all = `${allStartFormatted} - ${allEnd}`;
  appStore.chartDates.trailing12 = `${trailing12StartFormatted} - ${allEnd}`;

  appStore.allChartData[index] = allChartData;
  appStore.trailing12ChartData[index] = trailing12ChartData;
  appStore.chartDataTimestamps[index] = new Date();
};

/**
 * Munge term structure data into arrays
 */
export const mungeTermStructureData = (data, type) => {
  // term structure order
  const cmt = ["1m", "6m", "1y", "2y", "3y", "5y", "7y", "10y", "20y", "30y"];
  const ivol = ["1w", "1m", "2m", "3m", "6m", "1y"];
  const epsAndPe = ["0y", "1y", "2y", "3y"];
  const ffHike = [
    "1m",
    "2m",
    "3m",
    "4m",
    "5m",
    "6m",
    "7m",
    "8m",
    "9m",
    "10m",
    "11m",
  ];
  const terms = appStore.termStructureTerms;
  const allData = {};

  terms.forEach((term) => {
    // create unordered array like { item: <string>, value: <number> }
    const unordered = Object.keys(data[term]).map((datum) => {
      const item = datum.replace(type, "");

      return { item, value: data[term][datum] };
    });

    // create ordered array with length of unordered array
    const ordered = unordered.map((i) => i);

    // find index of item in cmt or ivol arrays and place it in that index of ordered array
    unordered.forEach(({ item, value }) => {
      const newValue = type === "estiv" ? value * 100 : value;

      let indexOfItem;

      if (type === "estcmt") {
        indexOfItem = cmt.indexOf(item);
      } else if (type === "estiv") {
        indexOfItem = ivol.indexOf(item);
      } else if (type === "FedExpHikes_") {
        indexOfItem = ffHike.indexOf(item);
      } else {
        indexOfItem = epsAndPe.indexOf(item);
      }

      ordered[indexOfItem] = { item, value: newValue };
    });

    allData[term] = ordered;
  });

  return allData;
};

/**
 * Prep data for the MANA Microstructure Indexes table
 */
export const prepMicrostructureTableData = () => {
  const { indexesList, indexesMicrostructureDailyData } = appStore;
  const { microstructure } = appStore.prefixes;

  const microstructureNames = indexesList.filter((i) =>
    i.startsWith(microstructure)
  );
  const microstructureTableHeaderRow = (() => {
    // Use the first microstructure index's dates.
    const { dates } = indexesMicrostructureDailyData[
      microstructureNames[0]
    ].data;
    const lastYear = `${dates[dates.length - 1].slice(0, 4) - 1}`;
    const lastYearHeader = `'${lastYear.slice(2, 4)}`;

    const twoYearsAgo = `${lastYear - 1}`;
    const midYearBetweenLastYearAnd2010 = `${Math.ceil(
      (+lastYear + 2010) / 2
    )}`;
    const midYearBetweenLastYearAnd2010Minus1 = `${+midYearBetweenLastYearAnd2010 - 1
      }`;
    const p1Header = `'${midYearBetweenLastYearAnd2010.slice(
      2,
      4
    )} - '${twoYearsAgo.slice(2, 4)}`;
    const p2Header = `'10 - '${midYearBetweenLastYearAnd2010Minus1.slice(
      2,
      4
    )}`;

    return [
      "",
      "Today",
      "10d",
      "60d",
      "ytd",
      lastYearHeader,
      p1Header,
      p2Header,
      "'08-'09",
      "'06-'07",
    ];
  })();

  const microstructureTableData = microstructureNames.reduce(
    (acc, microstructureName) => {
      const { dates, ret } = indexesMicrostructureDailyData[
        microstructureName
      ].data;
      const datesLength = dates.length;
      const endIndex = datesLength - 1;
      const latestYear = dates[endIndex].slice(0, 4);
      const lastYear = `${latestYear - 1}`;
      const years2010ToLastYear = (() => {
        const years = [];

        for (let i = +lastYear - 2009 - 1; i > 0; i--) {
          years.push(lastYear - i);
        }

        return years;
      })();

      const name = microstructureName.replace(microstructure, "");
      const todayCol = ret[ret.length - 1].toFixed(1);
      const parsedDate = parse(dates[datesLength - 1], yyyyMMdd, new Date());
      const twoMonthsAgo = subMonths(parsedDate, 2);
      const indexOfTwoMonthsAgo = closestIndexTo(
        twoMonthsAgo,
        dates.map((date) => parse(date, yyyyMMdd, new Date()))
      );
      const d10Array = [];
      const d60Array = [];

      ret.forEach((item, index) => {
        if (index >= datesLength - 10) d10Array.push(item);
        if (index >= indexOfTwoMonthsAgo) d60Array.push(item);
      });

      const d10 = utils.getAverageFromArray(d10Array).toFixed(1);
      const d60 = utils.getAverageFromArray(d60Array).toFixed(1);

      const ytd = utils.getAverageReturnFromDatesArray(ret, dates, [
        latestYear,
      ]);
      const yPrev = utils.getAverageReturnFromDatesArray(ret, dates, [
        lastYear,
      ]);
      const p1 = utils.getAverageReturnFromDatesArray(
        ret,
        dates,
        years2010ToLastYear.slice(years2010ToLastYear.length / 2 + 1)
      );

      const p2 = utils.getAverageReturnFromDatesArray(
        ret,
        dates,
        years2010ToLastYear.slice(0, years2010ToLastYear.length / 2 + 1)
      );
      const y0809 = utils.getAverageReturnFromDatesArray(ret, dates, [
        "2008",
        "2009",
      ]);
      const y0607 = utils.getAverageReturnFromDatesArray(ret, dates, [
        "2006",
        "2007",
      ]);

      acc.push({
        name,
        todayCol,
        d10,
        d60,
        ytd,
        yPrev,
        p1,
        p2,
        y0809,
        y0607,
      });

      return acc;
    },
    []
  );

  appStore.microstructureTableHeaderRow = microstructureTableHeaderRow;
  appStore.microstructureTableData = microstructureTableData;
};

/**
 * Munge the earings/edgar data
 */
export const mungeEarningsData = (dataset, indexes, type) => {
  const { dates } = dataset[indexes[0]].data;
  const startIndexOfPastEightQuarters = utils.getStartIndexOfPastEightQuarters(
    dates
  );

  appStore.indexesData[type] = [];

  const mungedData = [];

  dates.forEach((date, i) => {
    if (i >= startIndexOfPastEightQuarters) {
      let dn;
      let up;
      let start;

      indexes.forEach((index) => {
        const value = dataset[index].data.ret[i];

        if (index.endsWith("dn")) dn = value;
        if (index.endsWith("up")) up = value;
        if (index.endsWith("start")) start = value;
      });

      mungedData.push({ date, dn, up, start });
    }
  });

  appStore.indexesData[type] = mungedData;
};

/**
 * Split up the indexes into categories
 */
const categorizeIndexBarChartValues = (indexBarChartValues) => {
  const { indexNamesByCategory } = appStore;
  const indexBarChartValuesByCategory = {};
  const categories = Object.keys(indexNamesByCategory);

  categories.forEach((category) => {
    indexBarChartValues.filter((item) => {
      if (indexNamesByCategory[category].includes(item.item)) {
        if (!indexBarChartValuesByCategory[category])
          indexBarChartValuesByCategory[category] = [];

        indexBarChartValuesByCategory[category].push(item);
      }

      return null;
    });
  });

  indexBarChartValuesByCategory.meanReversion.sort((a, b) => {
    return a.item.localeCompare(b.item, "en", { numeric: true });
  });

  appStore.indexBarChartValuesByCategory = indexBarChartValuesByCategory;
};

/**
 * Gathers all the values for the indexes bar chart
 * and separates them into their own array in the store
 */
export const mungeBarChartValues = async () => {
  const {
    indexesCurrentValues,
    indexesList,
    indexNamesByCategory: { meanReversion, quantamentals, risk },
  } = appStore;
  const indexBarChartValues = [];
  const indexBarChartValuesOnly = [];

  indexesList.forEach((item) => {
    const trimmedItem = utils.removeIndexPrefix(item);

    if (
      meanReversion.includes(trimmedItem) ||
      quantamentals.includes(trimmedItem) ||
      risk.includes(trimmedItem)
    ) {
      const { value } = indexesCurrentValues[item];

      indexBarChartValuesOnly.push(Math.abs(value));
      indexBarChartValues.push({
        value,
        item: trimmedItem,
      });
    }

    return null;
  });

  categorizeIndexBarChartValues(indexBarChartValues);

  const { indexBarChartValuesByCategory } = appStore;

  // Using this override for the domain to get all bar charts on a single zero line
  appStore.indexBarChartDomainOverride = [
    0,
    Math.max(...indexBarChartValuesOnly),
  ];
  appStore.indexBarChartValues = indexBarChartValues;

  appStore.indexBarChartDomainOverrides = {
    meanReversion: [
      0,
      Math.max(
        ...indexBarChartValuesByCategory.meanReversion.map((l) =>
          Math.abs(l.value)
        )
      ),
    ],
    quantamentals: [
      0,
      Math.max(
        ...indexBarChartValuesByCategory.quantamentals.map((l) =>
          Math.abs(l.value)
        )
      ),
    ],
    risk: [
      0,
      Math.max(
        ...indexBarChartValuesByCategory.risk.map((l) => Math.abs(l.value))
      ),
    ],
  };
};
