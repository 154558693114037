import React, { Component } from "react";
import { view } from "@risingstack/react-easy-state";
import {
  NavLink,
  HashRouter as Router,
  Redirect,
  Route,
} from "react-router-dom";

import Logo from "../Logo";
// import GoogleSignIn from "../GoogleSignIn/index";
import DashboardPane from "../Panes/DashboardPane";
import DispersionPane from "../Panes/DispersionPane";
import MarketStatus from "../MarketStatus";
import StockPane from "../Panes/StockPane";
import TermsAndConditions from "../Panes/TermsAndConditions";

import appStore from "../../stores/appStore";

import "./ManaTech.css";

// const PROD = process.env.NODE_ENV === "production";

class ManaTech extends Component {
  componentDidMount() {
    /**
     * Automatically handles prod and dev environment login feature
     * Not secure at all, but neither is our current login scheme
     */
    // if (PROD) {
    //   appStore.authenticated = false;
    //   appStore.authenticatedUser = null;
    //   appStore.authenticationMessage = "";
    // } else {
    appStore.authenticated = true;
    appStore.authenticatedUser = "{ dev }";
    appStore.authenticationMessage = "development environment";
    // }
  }

  render() {
    // TODO: work on authentication
    // const { authenticated } = appStore;

    // if (!authenticated) return <GoogleSignIn />;

    const {
      // authenticatedUser,
      // windowWidth,
    } = appStore;
    // const centerAlignLightDarkAndLoginMenuItems = windowWidth < 900;

    // {
    //   menuItem: (
    //     <Menu.Item
    //       className="visible logout"
    //       key="Logout"
    //       onClick={handlers.handleLogout}
    //       position={`${centerAlignLightDarkAndLoginMenuItems ? "" : "right"}`}
    //     >
    //       Logout {authenticatedUser}
    //     </Menu.Item>
    //   ),
    // },


    /**
     * This first return is left here as a quick way to have a placeholder in case needed.
     */
    /*return (
      <div style={{ position: "absolute", overflow: 'hidden', top: 0, right: 0, bottom: 0, left: 0 }}>
        <div style={{ position: 'relative', opacity: '0.2', transform: 'translate(0, -50%)', top: '50%', left: '40px' }}>
          <Logo href="https://manatechllc.com" key="logo" width={100} />
        </div>
      </div>
    );*/

    return (
      <Router>
        <nav className="main-nav">
          <Logo href="https://manatechllc.com" key="logo" width={100} />
          <NavLink to="/dashboard">Dashboard</NavLink>
          <NavLink to="/stock" className="disabled">
            Stock
              </NavLink>
          <NavLink to="/earnings" className="disabled">
            Earnings
              </NavLink>
          <NavLink to="/cash-flow" className="disabled">
            Cash Flow
              </NavLink>
          <NavLink to="/dispersion">Dispersion</NavLink>
          <NavLink to="/dynamic-reports" className="disabled">
            Dynamic Reports
              </NavLink>
          <NavLink to="/terms-and-conditions">Terms & Conditions</NavLink>
          <a
            rel="noopener noreferrer"
            href="https://www.manatechllc.com/hire-us/"
            target="_blank"
          >
            Contact Us
              </a>
          <MarketStatus />
        </nav>
        <Route path="/" exact>
          <Redirect to="/dashboard" />
        </Route>
        <Route path="/dashboard">
          <DashboardPane />
        </Route>
        <Route path="/stock">
          <StockPane />
        </Route>
        <Route path="/dispersion">
          <DispersionPane />
        </Route>
        <Route path="/terms-and-conditions">
          <TermsAndConditions />
        </Route>
        <div className="disclosure">
          By viewing this site you agree to the&nbsp;
              <a href="/#/terms-and-conditions">Terms and Conditions of Access</a>
        </div>
      </Router>
    );
  }
}

export default view(ManaTech);
