import React from "react";
import ReactDOM from "react-dom";

import "./index.css";

import ManaTech from "./views/ManaTech";

if (module.hot) module.hot.accept();

ReactDOM.render(<ManaTech />, document.getElementById("mt-container"));
