import React from "react";
import PropTypes from "prop-types";

import { AutoSizer } from "react-virtualized";
import { AxisBottom, AxisLeft } from "@vx/axis";
import { extent } from "d3";
import { Group } from "@vx/group";
import { Bar } from "@vx/shape";
import { scaleLinear, scaleTime } from "@vx/scale";

import { mutedGreen, mutedRed } from "../../../../colors.json";

const strokeColor = "#c7c8ca";

const VerticalBarChart = ({ accessors, chartData, margin, type }) => {
  const { dateAccessor, upAccessor, dnAccessor } = accessors;

  const upAndDn = [];
  const shadedAreas = [];

  chartData.forEach((d, index) => {
    const { up, dn, start } = d;

    upAndDn.push(up, -dn);

    if (start === 1) shadedAreas.push(index);
  });

  return (
    <AutoSizer>
      {({ width, height }) => {
        if (!width || !height) return null;

        const limit = shadedAreas.length - 1;
        const shadeAreas = shadedAreas.map((area, index) => {
          const thisRegion = (i = 0) => {
            if (index + i > limit) return chartData.length;
            return shadedAreas[index + i];
          };

          const x = (thisRegion() / chartData.length) * width;
          const barWidth =
            ((thisRegion(1) - thisRegion()) / chartData.length) * width;

          return { x, barWidth };
        });

        const range = [margin.left, width - margin.left + margin.right];

        const xScale = scaleTime({
          range,
          domain: extent(chartData, dateAccessor),
        });

        const yScale = scaleLinear({
          range: [height - margin.top - margin.bottom, 0],
          domain: extent(upAndDn),
        });

        const barWidth = (range[1] - range[0]) / chartData.length;
        const xUp = dateAccessor;
        const yUp = upAccessor;
        const xDn = dateAccessor;
        const yDn = dnAccessor;

        const shadeAreasHeight = yScale.range()[0];
        const textFill = "#cccccc";
        const axisLeftTickLabelPropsFn = () => ({
          dx: "-0.5em",
          dy: "0.25em",
          fill: textFill,
          fontSize: 10,
          textAnchor: "end",
        });
        const axisBottomTickLabelPropsFn = () => ({
          dy: "0.25em",
          fill: textFill,
          fontSize: 10,
          textAnchor: "middle",
        });

        return (
          <svg width={width} height={height} fontSize={12}>
            <Group top={margin.top} left={margin.left}>
              {shadeAreas.map((shade, i) => {
                if (i % 2) {
                  return (
                    <Bar
                      key={`shade-${shade.x}-${shade.barWidth}`}
                      width={shade.barWidth}
                      height={shadeAreasHeight}
                      x={shade.x}
                      y={0}
                      fill="#212121"
                      shapeRendering="crispEdges"
                    />
                  );
                }

                return null;
              })}

              {chartData.map((d, index) => {
                let barHeightUp = yScale(0) - yScale(yUp(d));

                if (Number.isNaN(barHeightUp)) barHeightUp = 0;

                const barXUp = xScale(xUp(d));
                const barYUp = yScale(0) - barHeightUp;

                const barHeightDn = yScale(0) - yScale(yDn(d));
                const barXDn = xScale(xDn(d));
                const barYDn = yScale(0);

                return (
                  <Group
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${type}${index}`}
                  >
                    <Bar
                      width={barWidth}
                      height={barHeightUp}
                      x={barXUp}
                      y={barYUp}
                      fill={mutedGreen}
                      shapeRendering="crispEdges"
                      stroke="none"
                    />
                    <Bar
                      width={barWidth}
                      height={barHeightDn}
                      x={barXDn}
                      y={barYDn}
                      fill={mutedRed}
                      shapeRendering="crispEdges"
                      stroke="none"
                    />
                  </Group>
                );
              })}

              <AxisBottom
                scale={xScale}
                top={yScale.range()[0]}
                stroke={strokeColor}
                tickLabelProps={axisBottomTickLabelPropsFn}
                tickStroke={strokeColor}
                tickFormat={(tick) =>
                  tick.toLocaleString("en-US", { month: "short" })
                }
                tickLength={8}
              />

              <AxisLeft
                left={margin.left}
                numTicks={4}
                scale={yScale}
                stroke={strokeColor}
                tickLabelProps={axisLeftTickLabelPropsFn}
                tickStroke={strokeColor}
              />
            </Group>
          </svg>
        );
      }}
    </AutoSizer>
  );
};

export default VerticalBarChart;

VerticalBarChart.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.object).isRequired,
  margin: PropTypes.objectOf(PropTypes.number).isRequired,
  accessors: PropTypes.shape({
    date: PropTypes.func,
    up: PropTypes.func,
    dn: PropTypes.func,
    start: PropTypes.func,
  }).isRequired,
  type: PropTypes.string.isRequired,
};
