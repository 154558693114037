import React from "react";
import { view } from "@risingstack/react-easy-state";

import "./Header.css";

function Header({ children }) {
  return (
    <div className="primary-header">
      <h5>{children}</h5>
    </div>
  );
}

export default view(Header);
