import React from "react";
import { view } from "@risingstack/react-easy-state";

import "./LegendKeysInline.css";

function LegendKeysInline({ children: { legendKeys } }) {
  return <div className="legend-keys-inline">{legendKeys}</div>;
}

export default view(LegendKeysInline);
