import React from "react";
import { view } from "@risingstack/react-easy-state";

import "./MANALoader.css";

function MANALoader() {
  return (
    <div className="mana-loader">
      <svg viewBox="0 0 48 12" xmlns="http://www.w3.org/2000/svg">
        <circle className="one" cx={6} cy={6} r={6}></circle>
        <circle className="two" cx={24} cy={6} r={6}></circle>
        <circle className="three" cx={42} cy={6} r={6}></circle>
      </svg>
    </div>
  );
}

export default view(MANALoader);
