import React from "react";
import { view } from "@risingstack/react-easy-state";

import Block from "../../Block";
import Header from "../../Header";
import MicrostructureTable from "./MicrostructureTable";

const DispersionPane = () => (
  <Block>
    {{
      classes: "rtspot-indexes",
      content: <MicrostructureTable />,
      header: <Header>MANA Microstructure Indexes&trade;</Header>,
    }}
  </Block>
);

export default view(DispersionPane);
