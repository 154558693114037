import React, { Component } from "react";
import { view } from "@risingstack/react-easy-state";
import PropTypes from "prop-types";

import appStore from "../../../../stores/appStore";
import * as handlers from "../../../../actions/handlers";

import "./HeatmapCell.css";

class HeatmapCell extends Component {
  getStyleAndValue = () => {
    const { category, cell, currentRow, radii } = this.props;
    const isSharpe = cell === "Sharpe";
    const isYtd = cell.startsWith("20");
    const isAll = cell === "All";
    const cellHeader = isSharpe ? "All" : cell;
    const cellData = currentRow[cellHeader];

    if (!cellData) return { value: '', style: { backgroundColor: "#170f1e", } };

    const scales = appStore.heatmapScalesByCategory;
    const unalteredValue = isSharpe ? cellData.sharpe : cellData.ret;
    const border = "2px solid black";
    const style = {
      backgroundColor: "white",
      borderTopLeftRadius: radii.top.left ? "4px" : "0px",
      borderBottomLeftRadius: radii.bottom.left ? "4px" : "0px",
      borderTopRightRadius: radii.top.right ? "4px" : "0px",
      borderBottomRightRadius: radii.bottom.right ? "4px" : "0px",
    };

    let formattedValue = unalteredValue === undefined ? "NaN" : unalteredValue;

    if (formattedValue !== "NaN") {
      let scale;

      if (isAll) {
        formattedValue = `${Math.round(unalteredValue)}%`;
        scale = scales[category].allScale;
        style.borderRight = border;
      } else if (isYtd) {
        formattedValue = `${Math.round(unalteredValue / 100)}%`;
        scale = scales[category].ytdScale;
        style.borderRight = border;
      } else if (isSharpe) {
        formattedValue = unalteredValue.toFixed(1);
        scale = scales[category].sharpeScale;
        style.borderLeft = border;
      } else {
        formattedValue = Math.round(unalteredValue);
        scale = scales[category].mtdScale;
      }

      const scaledValue = scale(unalteredValue);

      style.backgroundColor = scaledValue;
    }

    return { style, value: formattedValue };
  };

  render() {
    const { value, style } = this.getStyleAndValue();
    const { cell, index, selected } = this.props;

    return (
      <div
        className={`heatmap-cell${selected ? " selected" : ""}`}
        id={`${index}-${cell}`}
        onClick={(e) => handlers.handleIndexYTickLabelClick(e, index)}
        style={style}
      >
        <span className="heatmap-cell-value" id={`${index}-${cell}`}>
          {value}
        </span>
      </div>
    );
  }
}

export default view(HeatmapCell);

HeatmapCell.propTypes = {
  category: PropTypes.string.isRequired,
  cell: PropTypes.string.isRequired,
  currentRow: PropTypes.shape({
    period: PropTypes.string,
    sharpe: PropTypes.number,
  }).isRequired,
  index: PropTypes.string.isRequired,
  radii: PropTypes.shape({
    top: PropTypes.shape({ left: PropTypes.bool, right: PropTypes.bool }),
    bottom: PropTypes.shape({ left: PropTypes.bool, right: PropTypes.bool }),
  }).isRequired,
  selected: PropTypes.bool,
};

HeatmapCell.defaultProps = {
  selected: false,
};
