import React, { Component } from "react";
import { view } from "@risingstack/react-easy-state";
import PropTypes from "prop-types";
import { scaleLinear } from "@vx/scale";

import { blue, mutedGreen, mutedRed } from "../../../../colors.json";

import HeatmapCell from "./HeatmapCell";

import appStore from "../../../../stores/appStore";
import * as handlers from "../../../../actions/handlers";
import * as requests from "../../../../actions/requests";
import * as utils from "../../../../actions/utils";

import "./HeatmapAndBarRow.css";

class HeatmapAndBarRow extends Component {
  async componentDidMount() {
    const { index } = this.props;
    const prefixedIndex = utils.addIndexPrefix(index);

    await requests.fetchIndexInfo(prefixedIndex);
  }

  render() {
    const { rangeMax, value, index, parentIndex } = this.props;
    const {
      heatmapRowsByIndexName,
      heatmapPeriodHeaders,
      indexBarChartDomainOverrides,
      indexNamesByCategory,
      selectedIndex,
      windowWidth,
    } = appStore;
    const prefixedIndex = utils.addIndexPrefix(index);
    const currentRow = heatmapRowsByIndexName[prefixedIndex];
    const selected = selectedIndex === prefixedIndex;

    if (!currentRow) return null;

    const notMobile = windowWidth >= 900;

    const columns = [
      "cell",
      "cell",
      "cell",
      "cell",
      "cell",
      "gap",
      "name",
      "gap",
      "zero-line",
      "bar",
    ];

    const nameStyle = {};

    let indexForDisplay = index;

    if (index === "RISK_OFF") indexForDisplay = "RISK OFF";

    return columns.map((col, ix) => {
      if (notMobile) {
        nameStyle.color = selected ? "white" : "gray";
        nameStyle.fontWeight = selected ? "bold" : "normal";

        if (col === "cell") {
          const cell = heatmapPeriodHeaders[ix];
          const radii = {
            top: { left: false, right: false },
            bottom: { left: false, right: false },
          };

          /**
           * the following puts a rounded border on the top left, top right,
           * bottom left and bottom right heatmap cells only
           */
          const categoryName = utils.getCategoryFromTrimmedIndexName(index);
          const category = indexNamesByCategory[categoryName];
          const { length } = category;

          if (cell === "All") {
            if (ix === 0) {
              if (category.indexOf(index) === 0) radii.top.left = true;
              if (category.indexOf(index) === length - 1)
                radii.bottom.left = true;
            }
          }

          if (cell === "Sharpe") {
            if (ix === 4) {
              if (category.indexOf(index) === 0) radii.top.right = true;
              if (category.indexOf(index) === length - 1)
                radii.bottom.right = true;
            }
          }

          return (
            <HeatmapCell
              category={categoryName}
              cell={cell}
              currentRow={currentRow}
              index={index}
              // eslint-disable-next-line react/no-array-index-key
              key={`${selectedIndex}${ix}${index}${cell}`}
              radii={radii}
              selected={selected}
            />
          );
        }
      } else {
        nameStyle.color = selected ? blue : "gray";
        nameStyle.fontWeight = selected ? "bold" : "normal";
      }

      if (col === "gap") {
        return (
          <div
            className={`grid-gap vertical${!notMobile && ix === 5 ? " not-mobile" : ""
              }`}
            // eslint-disable-next-line react/no-array-index-key
            key={`${selectedIndex}${ix}${index}`}
            onClick={(e) => handlers.handleIndexYTickLabelClick(e, index)}
          />
        );
      }

      const fqn = utils.getFullyQualifiedName(index);

      if (col === "name") {
        return (
          <div
            className="name-and-value-container"
            style={{ gridArea: `${parentIndex + 1}/7` }}
            onClick={(e) => handlers.handleIndexYTickLabelClick(e, index)}
            title={fqn}
            // eslint-disable-next-line react/no-array-index-key
            key={`${selectedIndex}${ix}${index}`}
          >
            <div className="name" style={nameStyle}>
              {indexForDisplay}
            </div>
          </div>
        );
      }

      if (col === "zero-line") {
        // eslint-disable-next-line react/no-array-index-key
        return (
          <div
            className="bar-chart-zero-line"
            // eslint-disable-next-line react/no-array-index-key
            key={`${selectedIndex}${ix}${index}`}
          />
        );
      }

      // if (windowWidth < 900) range = [0, (windowWidth - 150) / 2];

      if (col === "bar") {
        const categoryName = utils.getCategoryFromTrimmedIndexName(index);
        const xScale = scaleLinear({
          range: [0, rangeMax],
          domain: indexBarChartDomainOverrides[categoryName],
        });

        const barStyle = {
          backgroundColor: value >= 0 ? mutedGreen : mutedRed,
          width: xScale(Math.abs(value)),
        };

        return (
          <div
            className="bar-and-value"
            // eslint-disable-next-line react/no-array-index-key
            key={`${selectedIndex}${ix}${index}`}
            onClick={(e) => handlers.handleIndexYTickLabelClick(e, index)}
          >
            <div className="bar" id={index} style={barStyle} />
            <code
              className="bar-value"
              id={index}
              style={{ fontWeight: selected ? "bold" : "normal" }}
            >
              {(value * 10000).toFixed(1).toString()}
            </code>
          </div>
        );
      }

      return null;
    });
  }
}

export default view(HeatmapAndBarRow);

HeatmapAndBarRow.propTypes = {
  index: PropTypes.string.isRequired,
  rangeMax: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
