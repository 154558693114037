import React, { Component } from "react";
import { view } from "@risingstack/react-easy-state";

import IndexesOverview from "../IndexesOverview";

import appStore from "../../../../stores/appStore";
import * as intervals from "../../../../actions/intervals";

class IndustriesIndexes extends Component {
  componentDidMount() {
    intervals.startUpdateIndustriesIndexesDataInterval();
  }

  componentWillUnmount() {
    clearInterval(appStore.updateIndustriesIndexesDataInterval);
  }

  render() {
    const {
      activeIndustries,
      industriesCurrentValues,
      industriesDailyData,
      industriesIntradayData,
      prefixes,
    } = appStore;

    return (
      <IndexesOverview
        currentValues={industriesCurrentValues}
        dailyData={industriesDailyData}
        intradayData={industriesIntradayData}
        headerTitle="Industry"
        indexes={activeIndustries}
        replacer={prefixes.industry}
      />
    );
  }
}

export default view(IndustriesIndexes);
