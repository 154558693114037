import { URL_BASE } from "../constants";
import appStore from "../stores/appStore";
import * as utils from "./utils";
import * as processors from "./processors";

/**
 * Fetch all chart data
 */
export const fetchIndexDaily = async () => {
  // return;
  const { selectedIndex } = appStore;
  const allData = await utils.fetchData(
    `${URL_BASE}/indexes/${selectedIndex}/daily`
  );

  processors.mungeIndexDaily(allData, selectedIndex);
};

/**
 * Fetch intraday chart data
 */
export const fetchIndexIntraday = async () => {
  // return;
  utils.getIntradayDataIsReady();

  if (!appStore.intradayDataIsReady) return null;

  const { selectedIndex } = appStore;
  const data = await utils.fetchData(
    `${URL_BASE}/indexes/${selectedIndex}/intraday`
  );

  processors.mungeIndexIntraday(data, selectedIndex);

  return null;
};

/**
 * Fetches info for the supplied index and applies it to the store
 */
export const fetchIndexInfo = async (index) => {
  // return;
  const info = await utils.fetchData(`${URL_BASE}/indexes/${index}/info`);

  appStore.indexesInfo[index] = info;

  const { heatmap } = info;
  const headers = [];

  appStore.heatmapRowsByIndexName[index] = heatmap.reduce((acc, cell) => {
    const { period, sharpe } = cell;
    const ret = cell.return;

    headers.push(period);

    if (sharpe !== "NaN") acc[period] = { sharpe, ret };
    else acc[period] = { ret };

    return acc;
  }, {});

  appStore.heatmapPeriodHeaders = headers.concat("Sharpe");

  const trimmedIndex = utils.removeIndexPrefix(index);
  const category = utils.getCategoryFromTrimmedIndexName(trimmedIndex);

  appStore.heatmapValuesByCategory[category].all.push(heatmap[0].return);
  appStore.heatmapValuesByCategory[category].ytd.push(heatmap[1].return);
  appStore.heatmapValuesByCategory[category].mtd.push(
    heatmap[2].return,
    heatmap[3].return
  );
  appStore.heatmapValuesByCategory[category].sharpe.push(heatmap[0].sharpe);

  utils.setHeatmapScales();
};

/**
 * Fetch earnings/edgar chart data
 */
export const fetchEarningsData = async (type) => {
  // return;
  const indexes = utils.getEarningsIndexesNamesArray(type);

  const dataset = await indexes.reduce(async (acc, index) => {
    const accumulator = await acc;
    const data = await utils.fetchData(`${URL_BASE}/indexes/${index}/daily`);

    accumulator[index] = { data };

    return Promise.resolve(accumulator);
  }, Promise.resolve({}));

  processors.mungeEarningsData(dataset, indexes, type);
};

/**
 * Fetches all indexes
 */
export const fetchIndexList = async () => {
  appStore.indexesList = await utils.fetchData(`${URL_BASE}/indexes/list`);
};

/**
 * Fetches microstructure indexes data
 */
export const fetchIndexesDispersionDailyData = async () => {
  // return;
  if (!appStore.indexesList) await fetchIndexList();

  const { indexesList } = appStore;
  const { microstructure } = appStore.prefixes;

  const indexesMicrostructureDailyData = await indexesList.reduce(
    async (acc, index) => {
      const accumulator = await acc;

      if (index.startsWith(microstructure)) {
        const data = await utils.fetchData(
          `${URL_BASE}/indexes/${index}/daily`
        );

        accumulator[index] = { data };
      }

      return Promise.resolve(accumulator);
    },
    Promise.resolve({})
  );

  appStore.indexesMicrostructureDailyData = indexesMicrostructureDailyData;

  processors.prepMicrostructureTableData();
};

/**
 * Fetch IVol and CMT term structure data
 */
export const fetchIndexPoints = async () => {
  // return;
  const rawCmtChartData = await utils.fetchData(
    `${URL_BASE}/curves/cmt/points`
  );
  const rawIVolChartData = await utils.fetchData(
    `${URL_BASE}/curves/ivol/points`
  );
  const rawEpsChartData = await utils.fetchData(
    `${URL_BASE}/curves/eps/points`
  );
  const rawPeChartData = await utils.fetchData(`${URL_BASE}/curves/pe/points`);
  const rawFfHikeData = await utils.fetchData(
    `${URL_BASE}/curves/ff_hike/points`
  );

  const cmtChartData = processors.mungeTermStructureData(
    rawCmtChartData,
    "estcmt"
  );
  const iVolChartData = processors.mungeTermStructureData(
    rawIVolChartData,
    "estiv"
  );
  const epsChartData = processors.mungeTermStructureData(
    rawEpsChartData,
    "totmkteps"
  );
  const peChartData = processors.mungeTermStructureData(
    rawPeChartData,
    "totmktpe"
  );
  const ffHikeChartData = processors.mungeTermStructureData(
    rawFfHikeData,
    "FedExpHikes_"
  );

  appStore.termStructureChartData.cmtChartData = cmtChartData;
  appStore.termStructureChartData.iVolChartData = iVolChartData;
  appStore.termStructureChartData.epsChartData = epsChartData;
  appStore.termStructureChartData.peChartData = peChartData;
  appStore.termStructureChartData.ffHikeChartData = ffHikeChartData;
};

/**
 * Get the market status
 */
// export const fetchMarketStatus = async () => {
//   // return;
//   const status = await utils.fetchData(`${URL_BASE}/market_status`);

//   utils.setMarketStatus(status);
// };

/**
 * Fetches bar chart values
 */
export const fetchIndexValues = async () => {
  // return;
  const { mc, realAlpha, risk } = appStore.prefixes;

  const values = await appStore.indexesList.reduce(async (acc, item) => {
    const accumulator = await acc;
    const value = {};

    if (
      item.startsWith(mc) ||
      item.startsWith(realAlpha) ||
      item.startsWith(risk)
    ) {
      value.item = item;
      value.value = await utils.fetchData(`${URL_BASE}/indexes/${item}/val`);

      accumulator[item] = value;
    }

    return Promise.resolve(accumulator);
  }, Promise.resolve({}));

  appStore.indexesCurrentValues = values;

  processors.mungeBarChartValues();
};

/**
 * fetch indexes overview intraday data
 */
const fetchIndexesOverviewIntradayData = async (indexesToFetch, type) => {
  const intradayData = await indexesToFetch.reduce(async (acc, index) => {
    const accumulator = await acc;
    const data = await utils.fetchData(`${URL_BASE}/indexes/${index}/intraday`);
    const intraData = utils.getIntradayDataFrom945(data);

    accumulator[index] = { intraData };

    return Promise.resolve(accumulator);
  }, Promise.resolve({}));

  appStore[`${type}IntradayData`] = intradayData;
};

/**
 * fetch indexes overview daily data
 */
const fetchIndexesOverviewDailyData = async (indexesToFetch, type) => {
  const dailyData = await indexesToFetch.reduce(async (acc, index) => {
    const accumulator = await acc;
    const data = await utils.fetchData(`${URL_BASE}/indexes/${index}/daily`);
    const { dates } = data;
    const theData = dates.map((date, ix) => ({
      date: dates[ix],
      value: data.ret[ix],
    }));
    const endDate = dates[dates.length - 1];
    const indexOfTwelveMonthsAgo = utils.getIndexOfTwelveMonthsAgo(
      endDate,
      dates
    );
    const trailing12Data = theData.slice(indexOfTwelveMonthsAgo);

    accumulator[index] = { trailing12Data };

    return Promise.resolve(accumulator);
  }, Promise.resolve({}));

  appStore[`${type}DailyData`] = dailyData;
};

/**
 * Fetches rt spot and industries index current values
 */
export const fetchIndexesOverviewValues = async (indexesToFetch, type) => {
  // return;
  const values = await indexesToFetch.reduce(async (acc, item) => {
    const accumulator = await acc;
    const value = {};

    value.item = item;
    value.value =
      (await utils.fetchData(`${URL_BASE}/indexes/${item}/val`)) * 100;

    accumulator[item] = value;

    return Promise.resolve(accumulator);
  }, Promise.resolve({}));

  appStore[`${type}CurrentValues`] = values;
};

/**
 * Fetch indexes overview data
 */
export const fetchIndexesOverviewData = async (type) => {
  if (!appStore.indexesList) await fetchIndexList();

  let indexesToFetch;

  if (type === "industries") {
    const activeIndustries = await utils.fetchData(
      `${URL_BASE}/indexes/active_industries`
    );

    indexesToFetch = activeIndustries;
    appStore.activeIndustries = activeIndustries.reverse();
  } else if (type === "rtSpot") {
    const activeRtSpotIndexes = await utils.fetchData(
      `${URL_BASE}/indexes/active_rtspots`
    );

    // TODO: remove this filter when we get data back for RTSPOT_GOLD/OIL/SILVER
    const activeRtSpotIndexesWithoutGoldSilverOil = activeRtSpotIndexes.filter(
      (i) => {
        if (i !== "RTSPOT_GOLD" && i !== "RTSPOT_SILVER" && i !== "RTSPOT_OIL")
          return i;
        return null;
      }
    );

    indexesToFetch = activeRtSpotIndexesWithoutGoldSilverOil;
    appStore.activeRtSpotIndexes = activeRtSpotIndexesWithoutGoldSilverOil.reverse();

    // indexesToFetch = activeRtSpotIndexes;
    // appStore.activeRtSpotIndexes = activeRtSpotIndexes.reverse();
  }

  await fetchIndexesOverviewIntradayData(indexesToFetch, type);
  await fetchIndexesOverviewDailyData(indexesToFetch, type);
  await fetchIndexesOverviewValues(indexesToFetch, type);
};
