import React from "react";
import { view } from "@risingstack/react-easy-state";
import "./PlaybackControls.css";
function PlaybackControls(_a) {
    var _b = _a.children, handleBackButtonClick = _b.handleBackButtonClick, handlePlayPauseButtonClick = _b.handlePlayPauseButtonClick, handleForwardButtonClick = _b.handleForwardButtonClick, id = _b.id, isPlaying = _b.isPlaying;
    return (React.createElement("div", { className: "playback-controls" },
        React.createElement("div", { id: id, className: "bwd", onClick: handleBackButtonClick },
            React.createElement("div", { className: "arrow" }),
            React.createElement("div", { className: "arrow" })),
        React.createElement("div", { id: id, className: "" + (isPlaying ? "pause" : "play arrow"), onClick: handlePlayPauseButtonClick }),
        React.createElement("div", { id: id, className: "fwd", onClick: handleForwardButtonClick },
            React.createElement("div", { className: "arrow" }),
            React.createElement("div", { className: "arrow" }))));
}
export default view(PlaybackControls);
