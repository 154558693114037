import React from "react";
import { view } from "@risingstack/react-easy-state";

import appStore from "../../../../stores/appStore";
import * as utils from "../../../../actions/utils";

import IndexHeatmapBarChart from "./IndexHeatmapBarChart/IndexHeatmapBarChart";

import AllDataChart from "../AllDataChart";
import Block from "../../../Block";
import Information from "../Information";
import InfoData from "../Information/InfoData";
import IntradayChart from "../IntradayChart";
import QuantamentalIndexesLegend from "../../../QuantamentalIndexesLegend";
import Trailing12Chart from "../Trailing12Chart";

import "./IndexSectionContainer.css";
import "../Chart.css";

function IndexSectionContainer() {
  const { indexHeatmapBarChartCurrentCategory, selectedIndex } = appStore;

  const fqn = utils.getFullyQualifiedName(selectedIndex);
  const titles = InfoData.realAlphaIndexes.items.graphs;

  return (
    <div className="alpha-indexes-container">
      {/* Indexes Heatmap & Bar charts section container */}
      <IndexHeatmapBarChart activeTab={indexHeatmapBarChartCurrentCategory} />

      {/* Charts Header */}
      <QuantamentalIndexesLegend>
        {{
          legendKeys: [
            { color: "blue", text: fqn },
            {
              color: "gray",
              text: (
                <>
                  MANA Equity Market Neutral LiquidUSRisk Index&trade;{" "}
                  <span>(right axis)</span>
                </>
              ),
            },
          ],
        }}
      </QuantamentalIndexesLegend>

      {/* Information Panel */}
      <Block>
        {{
          basic: true,
          classes: "information",
          content: <Information />,
        }}
      </Block>

      {/* Intraday */}
      <Block>
        {{
          basic: true,
          classes: "intraday-chart",
          content: <IntradayChart />,
          title: titles.intraday,
        }}
      </Block>

      {/* YTD */}
      <Block>
        {{
          basic: true,
          classes: "ytd-chart",
          content: <Trailing12Chart />,
          title: titles.trailing12,
        }}
      </Block>

      {/* Ten Year */}
      <Block>
        {{
          basic: true,
          classes: "ten-yr-chart",
          content: <AllDataChart />,
          title: titles.all,
        }}
      </Block>
    </div>
  );
}

export default view(IndexSectionContainer);
