import React from "react";
import { view } from "@risingstack/react-easy-state";

import MANALoader from "../../../MANALoader";
import ShadedLineChart from "../../../ShadedLineChart";
import ShadedLineChartLegend from "../../../ShadedLineChartLegend";

import appStore from "../../../../stores/appStore";
import * as utils from "../../../../actions/utils";

function Trailing12Chart() {
  const { selectedIndex, trailing12ChartData } = appStore;

  const chartData = trailing12ChartData[selectedIndex];
  const riskData = trailing12ChartData.Risk_LiquidUSRisk;

  if (!chartData) return <MANALoader />;

  const margin = {
    left: 24,
    right: 50,
    top: 14,
    bottom: 14,
  };

  const dates = chartData.map(({ date }) => {
    const year = +date.substring(0, 4);
    const month = +date.substring(4, 6) - 1;
    const day = +date.substring(6);
    const theDate = new Date(year, month, day);

    return theDate.getMonth();
  });

  const shadedAreasIndexes = utils.getShadedAreasIndexes(dates);
  const yTickFormatter = (tick) => `${(+tick * 100).toFixed(1)}%`;

  return (
    <>
      <ShadedLineChartLegend>
        {{
          chart: "trailing12",
          title: "Trailing 12 months",
        }}
      </ShadedLineChartLegend>
      <div style={{ height: "calc(100% - 18px)" }}>
        <ShadedLineChart
          chartData={chartData}
          riskData={riskData}
          margin={margin}
          shadedAreasIndexes={shadedAreasIndexes}
          shadeYtd
          xTickFormat={{
            exclude: [],
            prefix: "",
            dateFormat: "MMM",
            suffix: "",
          }}
          yTickFormatter={yTickFormatter}
        />
      </div>
    </>
  );
}

export default view(Trailing12Chart);
