import React from "react";
import { view } from "@risingstack/react-easy-state";
import classNames from "classnames";
import debounce from "debounce";

import HeatmapAndBarHeaders from "../../HeatmapAndBarHeaders";
import HeatmapAndBarRow from "../../HeatmapAndBarRow";
import MANALoader from "../../../../MANALoader";
import Tabs from "../../../../Tabs";

import appStore from "../../../../../stores/appStore";
import * as intervals from "../../../../../actions/intervals";
import { removeIndexPrefix } from "../../../../../actions/utils";

import "./IndexHeatmapBarChart.css";

function IndexHeatmapBarChart({ activeTab }) {
  const qtDiv = React.useRef(null);
  const mrDiv = React.useRef(null);
  const rkDiv = React.useRef(null);

  const [qtDivPillHeight, setQtDivPillHeight] = React.useState(54);
  const [mrDivPillHeight, setMrDivPillHeight] = React.useState(54);
  const [rkDivPillHeight, setRkDivPillHeight] = React.useState(54);

  const [qtLength, setQtLength] = React.useState(0);
  const [mrLength, setMrLength] = React.useState(0);
  const [rkLength, setRkLength] = React.useState(0);

  React.useEffect(() => {
    intervals.startUpdateDataInterval();

    window.addEventListener("resize", updateWindowSizeDebounced);

    return () => {
      clearInterval(appStore.updateDataInterval);

      window.removeEventListener("resize", updateWindowSizeDebounced);
    };
  });

  React.useEffect(() => {
    if (qtDiv.current) {
      setQuantamentalsLength();
      setQtDivPillHeight(qtDiv.current.clientHeight / qtLength);
    }

    if (mrDiv.current) {
      setMeanReversionLength();
      setMrDivPillHeight(mrDiv.current.clientHeight / mrLength);
    }

    if (rkDiv.current) {
      setRiskLength();
      setRkDivPillHeight(rkDiv.current.clientHeight / rkLength);
    }
  });

  function updateWindowSizeDebounced() {
    debounce(updateWindowSize, 1000);
  }

  function updateWindowSize() {
    appStore.windowWidth = window.innerWidth;
  }

  if (!appStore.indexBarChartValuesByCategory) return <MANALoader />;

  const {
    meanReversion,
    quantamentals,
    risk,
  } = appStore.indexBarChartValuesByCategory;

  function setQuantamentalsLength() {
    setQtLength(quantamentals.length);
  }

  function setMeanReversionLength() {
    setMrLength(meanReversion.length);
  }

  function setRiskLength() {
    setRkLength(risk.length);
  }

  const quantamentalsIsActive = { active: activeTab === "quantamentals" };
  const meanReversionIsActive = { active: activeTab === "meanReversion" };
  const riskIsActive = { active: activeTab === "risk" };

  const quantamentalsSectionClasses = classNames(
    "quantamentals category",
    quantamentalsIsActive
  );
  const meanReversionSectionClasses = classNames(
    "mean-reversion category",
    meanReversionIsActive
  );
  const riskSectionClasses = classNames("risk category", riskIsActive);
  const rangeMax = { quantamentals: 0, meanReversion: 0, risk: 0 };

  function getWidestBar(value) {
    const stringValue = (value * 10000).toFixed(1).toString();

    // 7.3 is a little more than the width of each char
    const stringWidth = 7.3 * stringValue.split("").length;

    // 155 is fixed width of the containing grid area
    // 4 is the right margin of the bar
    return 155 - 4 - stringWidth;
  }

  rangeMax.quantamentals = Math.min(
    ...quantamentals.map(({ value }) => getWidestBar(value))
  );

  rangeMax.meanReversion = Math.min(
    ...meanReversion.map(({ value }) => getWidestBar(value))
  );

  rangeMax.risk = Math.min(...risk.map(({ value }) => getWidestBar(value)));

  const pillQt = activeTab === "quantamentals";
  const pillMr = activeTab === "meanReversion";
  const pillRk = activeTab === "risk";

  const selectedIndex = removeIndexPrefix(appStore.selectedIndex);

  const { indexNamesByCategory } = appStore;

  const mrNameByCat = indexNamesByCategory.meanReversion;
  const qtNameByCat = indexNamesByCategory.quantamentals;
  const rkNameByCat = indexNamesByCategory.risk;

  const mrIndexOf = mrNameByCat.indexOf(selectedIndex);
  const qtIndexOf = qtNameByCat.indexOf(selectedIndex);
  const rkIndexOf = rkNameByCat.indexOf(selectedIndex);

  let index;
  let top;

  if (mrIndexOf > -1) {
    index = mrIndexOf;
    top = `${mrDivPillHeight * index}px`;
  }

  if (qtIndexOf > -1) {
    index = qtIndexOf;
    top = `${qtDivPillHeight * index}px`;
  }

  if (rkIndexOf > -1) {
    index = rkIndexOf;
    top = `${rkDivPillHeight * index}px`;
  }

  const pillQtStyle = {
    height: `${qtDivPillHeight - 3}px`,
    top,
  };

  const pillMrStyle = {
    height: `${mrDivPillHeight - 3}px`,
    top,
  };

  const pillRkStyle = {
    height: `${rkDivPillHeight - 3}px`,
    top,
  };

  return (
    <>
      <Tabs activeTab={activeTab} />
      <section className="heat-map-and-bar-chart-container">
        <HeatmapAndBarHeaders />

        <div className={quantamentalsSectionClasses} ref={qtDiv}>
          <div
            className={classNames("name-pill", {
              qt: pillQt,
            })}
            style={pillQtStyle}
          ></div>
          {quantamentals.map(({ item, value }, parentIndex) => (
            <HeatmapAndBarRow
              index={item}
              parentIndex={parentIndex}
              key={`quantamentals-heatmap${item}${value}`}
              rangeMax={rangeMax.quantamentals}
              value={value}
            />
          ))}
        </div>

        <div className={meanReversionSectionClasses} ref={mrDiv}>
          <div
            className={classNames("name-pill", {
              mr: pillMr,
            })}
            style={pillMrStyle}
          ></div>
          {meanReversion.map(({ item, value }, parentIndex) => (
            <HeatmapAndBarRow
              index={item}
              parentIndex={parentIndex}
              key={`mean-reversion-heatmap${item}${value}`}
              rangeMax={rangeMax.meanReversion}
              value={value}
            />
          ))}
        </div>

        <div className={riskSectionClasses} ref={rkDiv}>
          <div
            className={classNames("name-pill", {
              rk: pillRk,
            })}
            style={pillRkStyle}
          ></div>
          {risk.map(({ item, value }, parentIndex) => (
            <HeatmapAndBarRow
              index={item}
              parentIndex={parentIndex}
              key={`risk-heatmap${item}${value}`}
              rangeMax={rangeMax.risk}
              value={value}
            />
          ))}
        </div>
      </section>
    </>
  );
}

export default view(IndexHeatmapBarChart);
