import React from "react";
import { view } from "@risingstack/react-easy-state";
import classNames from "classnames";

import "./Block.css";

function Block({
  children: { basic, classes = "", content, header, info, title },
}) {
  const panel = info?.panel;
  const trigger = info?.trigger;
  const blockClasses = classNames("block", { basic: basic }, classes);

  return (
    <section title={title} className={blockClasses}>
      {panel}
      {trigger}
      {header}
      {content}
    </section>
  );
}

export default view(Block);
