import React from "react";

export default {
  realAlphaIndexes: {
    html: (
      <>
        <ul>
          Index Level Data
          <li>
            <span>All (Ann. %)</span> &mdash; Annualized rate of return since
            the inception of the index.
          </li>
          <li>
            <span>2018 (%)</span> &mdash; Current Year-to-Date return.
          </li>
          <li>
            <span>Month (bps)</span> &mdash; Prior month’s performance and
            current month-to-date performance.
          </li>
          <li>
            <span>LTD Sharpe</span> &mdash; Life-to-Date Sharpe ratio = (average
            monthly returns/standard deviation of monthly returns) * 16
          </li>
          <li>
            <span>Performance (bps)</span> &mdash; Intraday performance.
          </li>
        </ul>
        <ul>
          Stats
          <li>
            <span>Holding Period</span> &mdash; Average holding period of
            underlying positions, in days.
          </li>
          <li>
            <span>Return</span> &mdash; Return (%) for specified date range.
          </li>
          <li>
            <span>Sharpe</span> &mdash; Sharpe ratio for specified date range.
          </li>
          <li>
            <span>Volatility</span> &mdash; Standard deviation of monthly
            returns for specified date range.
          </li>
          <li>
            <span>Correlation to SPY</span> &mdash; Correlation over the life of
            the index.
          </li>
          <li>
            <span>Sharpe (Market up)</span> &mdash; Sharpe ratio of daily
            returns of index when the price of SPY increased.
          </li>
          <li>
            <span>Sharpe (Market down)</span> &mdash; Sharpe ratio of daily
            returns of index when the price of SPY decreased.
          </li>
        </ul>
        <ul>
          Graphs
          <li>
            <span>MANA Equity Market Neutral LiquidUSRisk Index&trade;</span>:
            Liquidity weighted portfolio intended to represent the general US
            equity market.
          </li>
          <li>
            <span>Top</span> &mdash; Current intraday return marked to the mid
            price (bps).
          </li>
          <li>
            <span>Middle</span> &mdash; Trailing 12 months return (%).
          </li>
          <li>
            <span>Bottom</span> &mdash; Inception-to-Date return (%).
          </li>
        </ul>
      </>
    ),
    items: {
      heatMapAndBars: {
        all: "Annualized rate of return since the inception of the index.",
        ytd: "Current Year-to-Date return.",
        month:
          "Prior month’s performance and current month-to-date performance.",
        ltdSharpe:
          "Life-to-Date Sharpe ratio = (average monthly returns/standard deviation of monthly returns) * 16",
        perf: "Intraday performance.",
      },
      stats: {
        holdingPeriod:
          "Average holding period of underlying positions, in days.",
        ret: "Return (%) for specified date range.",
        sharpe: "Sharpe ratio for specified date range.",
        vol: "Standard deviation of monthly returns for specified date range.",
        corr: "Correlation over the life of the index.",
        sharpeUp:
          "Sharpe ratio of daily returns of index when the price of SPY increased.",
        sharpeDn:
          "Sharpe ratio of daily returns of index when the price of SPY decreased.",
      },
      graphs: {
        intraday: "Current intraday return marked to the mid price (bps).",
        trailing12: "Trailing 12 months return (%).",
        all: "Inception-to-Date return (%).",
      },
    },
  },
  earnings: {
    html: (
      <>
        <div>
          Green &mdash; Number of companies whose stock price increased when
          earnings announced.
        </div>
        <div>
          Red &mdash; Number of companies whose stock price decreased when
          earnings announced.
        </div>
        <br />
        <div>
          Note: Stock price movement is captured on the next trading day for
          companies who announce after the stock markets close.
        </div>
      </>
    ),
    items: `Green – Number of companies whose stock price increased when earnings announced.
Red – Number of companies whose stock price decreased when earnings announced.
Note: Stock price movement is captured on the next trading day for companies who announce after the stock markets close.`,
  },
  edgar: {
    html: (
      <>
        <div>
          Green &mdash; Number of companies whose stock price increased when
          10Q/10K released.
        </div>
        <div>
          Red &mdash; Number of companies whose stock price decreased when
          10Q/10K released.
        </div>
        <br />
        <div>
          Note: Stock price movement is captured on the next trading day for
          companies who file after the stock markets close.
        </div>
      </>
    ),
    items: `Green – Number of companies whose stock price increased when 10Q/10K released.
Red – Number of companies whose stock price decreased when 10Q/10K released.
Note: Stock price movement is captured on the next trading day for companies who announce after the stock markets close.`,
  },
};
