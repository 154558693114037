import React from "react";
import { view } from "@risingstack/react-easy-state";

import "./Dropdown.css";

function Dropdown({ children: { handleChange, options, value } }) {
  return (
    <select className="mana-dropdown" value={value} onChange={handleChange}>
      {options.map(({ text, value: val }) => {
        return (
          <option key={val} value={val}>
            {text}
          </option>
        );
      })}
    </select>
  );
}

export default view(Dropdown);
