import React from "react";
import PropTypes from "prop-types";

const Logo = ({ animate, href, title, width }) => {
  const svg = (
    <svg
      x="0px"
      y="0px"
      height={`${width * 0.4}px`}
      width={width}
      viewBox="0 150 1280 500"
      enableBackground="new 0 0 1280 800"
    >
      <defs>
        {/* create gradient for 'loading' animation */}
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0">
          <stop offset="0%" style={{ stopColor: "#9E7FBA" }} />
          <stop offset="25%" style={{ stopColor: "#72647F" }} />
          <stop offset="50%" style={{ stopColor: "#4d4d4d" }} />
          <stop offset="75%" style={{ stopColor: "#72647F" }} />
          <stop offset="100%" style={{ stopColor: "#9E7FBA" }} />
        </linearGradient>

        {/* stitch gradients together for seamless animation */}
        <pattern
          id="pattern"
          x="0"
          y="0"
          width="300%"
          height="100%"
          patternUnits="userSpaceOnUse"
        >
          <rect x="0" y="0" width="150%" height="100%" fill="url(#gradient)">
            <animate
              attributeType="XML"
              attributeName="x"
              from="0"
              to="149%"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect
            x="-150%"
            y="0"
            width="150%"
            height="100%"
            fill="url(#gradient)"
          >
            <animate
              attributeType="XML"
              attributeName="x"
              from="-149%"
              to="0"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
        </pattern>
      </defs>
      <g>
        <path
          fill={animate ? "url(#pattern)" : "#9E7FBA"}
          d="M542,355.8c-4.6-11.3-15.4-10-19.6-0.8c-3.3,8.3-48.4,117.2-52.1,126.3c-3.8,9.2-0.8,21.7,10.8,21.7c11.7,0,81.3,0,98.4,0c17.1,0,17.9-13.3,14.6-21.7C590.8,473,544.9,362.9,542,355.8z"
        />
        <path
          fill={animate ? "url(#pattern)" : "#9E7FBA"}
          d="M1222.9,145.7H57.1C25.4,145.7,0,171.1,0,202.8v394.4c0,31.7,25.4,57.1,57.1,57.1h1165.8c31.7,0,57.1-25.4,57.1-57.1V202.8C1280,171.1,1254.6,145.7,1222.9,145.7z M374,529.7c0,9.6-7.5,19.2-17.1,19.2c-4.2,0-9.2,0-12.9,0c-6.7,0-16.3-8.8-16.3-18.3c0-6.7,0-203.5,0-210.6c0-5.8-3.8-6.3-5.4-2.1c-2.1,5.4-72.5,202.2-77.1,212.6c-10.4,25.9-44.6,22.9-53.4-0.4c-1.7-4.6-75-208.9-76.7-213.1c-1.3-2.9-5.4-2.1-5.4,2.1c0,2.9,0,205.1,0,212.6c0,6.3-7.1,17.1-17.5,17.1c-2.9,0-9.2,0-12.5,0c-5.4,0-15.8-7.9-15.8-18.8c0-7.1,0-250.2,0-259.8c0-10,8.8-19.2,17.9-19.2c9.6,0,25.9,0,34.6,0c10.8,0,22.1,9.6,25.4,19.2c2.9,9.2,73.4,208.9,75,213.1c2.5,6.7,4.6,6.7,7.1,0.4c1.3-3.8,71.7-208.9,73.8-214.3c2.9-7.9,14.6-18.3,24.6-18.3c6.7,0,25.9,0,33.8,0c9.2,0,17.9,7.9,17.9,20.4C374,278.7,374,524.7,374,529.7z M653.8,548.8c-14.2,0-235.2,0-245.2,0c-10,0-12.5-8.8-10-15c2.5-6.3,106.7-255.2,109.7-263.1c10.8-26.3,36.7-26.7,47.9,0.4c2.9,7.1,106.7,256.8,108.8,261.4C667.1,537.6,668.4,548.8,653.8,548.8z M940.2,533c0,7.1-7.1,15.8-17.1,15.8c-5,0-7.5,0-11.7,0c-7.5,0-16.7-7.5-16.7-20.8c0-9.6,0-202.2,0-210.6c0-10.8-10.4-20.8-23.3-20.8c-10.8,0-104.7,0-113,0c-10.8,0-22.9,11.3-22.9,20.8c0,10.8,0,210.6,0,214.7c0,7.9-7.9,17.1-15.4,17.1c-5,0-9.2,0-12.9,0c-5.8,0-15.8-7.5-15.8-16.3c0-6.7,0-260.2,0-266c0-7.5,9.2-15.8,16.7-15.8c3.8,0,206.4,0,214.3,0c9.6,0,18.3,7.1,18.3,17.9C940.2,277,940.2,528.4,940.2,533z M1220.8,548.8c-14.2,0-235.2,0-245.2,0c-10,0-12.5-8.8-10-15c2.5-6.3,106.7-255.2,109.7-263.1c10.8-26.3,36.7-26.7,47.9,0.4c2.9,7.1,106.7,256.8,108.8,261.4C1234.1,537.6,1235.4,548.8,1220.8,548.8z"
        />
        <path
          fill={animate ? "url(#pattern)" : "#9E7FBA"}
          d="M1109.1,355.8c-4.6-11.3-15.4-10-19.6-0.8c-3.3,8.3-48.4,117.2-52.1,126.3c-3.8,9.2-0.8,21.7,10.8,21.7s81.3,0,98.4,0s17.9-13.3,14.6-21.7C1157.8,473,1112,362.9,1109.1,355.8z"
        />
      </g>
    </svg>
  );

  let titleEl;

  if (title) {
    titleEl = (
      <div
        className="title"
        style={{
          fontSize: `${width * 0.2}px`,
          marginLeft: `${width * (2 / 30)}px`,
        }}
      >
        {title}
      </div>
    );
  }

  return (
    <div className="logo-wrapper" style={{ lineHeight: `${width * 0.4}px` }}>
      <a href={href} target="_blank" rel="noopener noreferrer">
        {svg}
        {titleEl}
      </a>
    </div>
  );
};

export default Logo;

Logo.propTypes = {
  animate: PropTypes.bool,
  href: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  width: PropTypes.number.isRequired,
};

Logo.defaultProps = {
  href: undefined,
  animate: false,
  title: "",
};
