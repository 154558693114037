import { store } from "@risingstack/react-easy-state";

const appStore = store({
  authenticated: false,
  authenticatedUser: "",
  authenticationMessage: "",

  // faking First Meaningful Paint to know that the bar charts have been rendered
  paint: false,

  intradayDataIsReady: false,
  marketIsOpen: null,
  indexesData: {},
  indexesInfo: {},
  chartDates: { all: "", intraday: "", trailing12: "" },
  allChartData: {},
  trailing12ChartData: {},
  chartDataTimestamps: {},
  intradayChartData: {},
  heatmapRowsByIndexName: {},
  heatmapPeriodHeaders: [],
  heatmapValuesByCategory: {
    meanReversion: { all: [], ytd: [], mtd: [], sharpe: [] },
    risk: { all: [], ytd: [], mtd: [], sharpe: [] },
    quantamentals: { all: [], ytd: [], mtd: [], sharpe: [] },
  },
  indexNamesByCategory: {
    quantamentals: [
      'Fundamentals',
      'Microstructure',
      'Options',
      'Ownership',
      'Price',
      'Valuation',
    ],
    meanReversion: ["CMR1", "CMR2", "CMR3", "CMR5", "CMR10"],
    risk: [
      "LiquidUSRisk",
      "MomentumRisk",
      "BetaRisk",
      "SizeRisk",
      "GoldRisk",
      "OilRisk",
      "EuroDollarRisk",
      "YenRisk",
    ],
  },
  prefixes: {
    microstructure: "Dispersion_",
    earnings: "ES_",
    edgar: "ED_",
    industry: "Industry_",
    mc: "MC_",
    realAlpha: "RealAlpha_",
    risk: "Risk_",
    rtSpot: "RTSPOT_",
  },
  termStructureChartData: {},
  termStructureTerms: ["current", "month_ago", "year_ago"],
  indexHeatmapBarChartCurrentCategory: "quantamentals",
  changeSelectedBarIntervalIsRunning: false,
  updateDataIntervalIsRunning: false,
  updateIndustriesIndexesDataIntervalIsRunning: false,
  updateRtSpotIndexesDataIntervalIsRunning: false,
  selectedIndex: "MC_Fundamentals",
  visibleInfoPanelIds: [],
});

export default appStore;
