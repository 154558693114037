import React from "react";
import { view } from "@risingstack/react-easy-state";
import "./LegendKey.css";
function LegendKey(_a) {
    var _b = _a.children, color = _b.color, text = _b.text;
    return (React.createElement("div", { className: "legend-key" },
        React.createElement("div", { className: "legend-symbol " + color }),
        React.createElement("span", null, text)));
}
export default view(LegendKey);
