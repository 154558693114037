import React from "react";
import { view } from "@risingstack/react-easy-state";
import { v4 as uuidv4 } from "uuid";

import InfoData from "../InfoData";
import MANALoader from "../../../../MANALoader";

import appStore from "../../../../../stores/appStore";
import * as utils from "../../../../../actions/utils";

import "./Stats.css";

const loader = <MANALoader />;

const Stats = () => {
  const { indexesInfo, selectedIndex } = appStore;

  if (!indexesInfo) return loader;

  const data = indexesInfo[selectedIndex];

  if (!data) return loader;

  const tableData = { headers: [] };
  const { spycor, spydn, spyup, stats } = data;
  const allCorr = spycor === "NaN" ? "NaN" : spycor.toFixed(2);
  const dnCorr = spydn === "NaN" ? "NaN" : spydn.toFixed(1);
  const upCorr = spyup === "NaN" ? "NaN" : spyup.toFixed(1);
  const titles = InfoData.realAlphaIndexes.items.stats;

  // Using utils.removeIndexPrefix(selectedIndex) here instead of data.name
  // since the risk indexes done't have a name coming in from the API
  const trimmedIndex = utils.removeIndexPrefix(selectedIndex);
  const { holdingPeriod, ret, sharpe, vol } = titles;

  tableData.headers.push(
    trimmedIndex,
    {
      text: "Holding Period",
      title: holdingPeriod,
    },
    {
      text: "Return",
      title: ret,
    },
    {
      text: "Sharpe",
      title: sharpe,
    },
    {
      text: "Volatility",
      title: vol,
    }
  );

  tableData.rows = Object.keys(stats).reduce((acc, item) => {
    const { hold, ret, sharpe, vol } = stats[item];

    const tr = {
      row: item,
      hold: hold === "NaN" ? NaN : hold.toFixed(1),
      ret: ret === "NaN" ? NaN : ret.toFixed(1),
      sharpe: sharpe === "NaN" ? NaN : sharpe.toFixed(1),
      vol: vol === "NaN" ? NaN : vol.toFixed(1),
    };

    acc.push(tr);

    return acc;
  }, []);

  const correlation = (
    <div className="corr-container">
      <div className="corr-stats">
        <span>
          <span className="corr-title" title={titles.corr}>
            Correlation to SPY
          </span>{" "}
          (All) {allCorr}
        </span>
        <span>
          <span className="corr-title" title={titles.sharpeUp}>
            Sharpe
          </span>{" "}
          (Market up): {upCorr}
        </span>
        <span>
          <span className="corr-title" title={titles.sharpeDn}>
            Sharpe
          </span>{" "}
          (Market down): {dnCorr}
        </span>
      </div>
    </div>
  );

  return (
    <div className="stats-container">
      <table className="stats-table">
        <thead>
          <tr>
            {tableData.headers.map((item, i) => {
              if (i === 0)
                return (
                  <th key={uuidv4()}>
                    <span>{item}</span>
                  </th>
                );
              return (
                <th key={uuidv4()}>
                  <span title={item.title}>{item.text}</span>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {tableData.rows.map((row) => {
            return (
              <tr key={uuidv4()}>
                <td>{row.row}</td>
                <td>{row.hold}</td>
                <td>{row.ret}</td>
                <td>{row.sharpe}</td>
                <td>{row.vol}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {correlation}
    </div>
  );
};

export default view(Stats);
