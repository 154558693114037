import {
  LONG_INTERVAL_FREQUENCY,
  SHORT_INTERVAL_FREQUENCY,
} from "../constants";
import appStore from "../stores/appStore";
import * as utils from "./utils";

/**
 * Starts the interval for advancing thru the bars
 */
export const startChangeSelectedBarInterval = () => {
  appStore.paint = true;

  appStore.changeSelectedBarInterval = setInterval(
    () => utils.changeSelectedBar({ next: true }),
    SHORT_INTERVAL_FREQUENCY
  );

  appStore.changeSelectedBarIntervalIsRunning = true;
};

/**
 * Starts the index bar chart values fetching interval
 */
export const startUpdateDataInterval = () => {
  if (!appStore.updateDataIntervalIsRunning) utils.updateData();

  appStore.updateDataInterval = setInterval(
    () => utils.updateData(),
    LONG_INTERVAL_FREQUENCY
  );
  appStore.updateDataIntervalIsRunning = true;
};

/**
 * Starts the industries indexes overview data fetching interval
 */
export const startUpdateIndustriesIndexesDataInterval = () => {
  if (!appStore.updateIndustriesIndexesDataIntervalIsRunning)
    utils.updateIndustriesIndexesData();

  appStore.updateIndustriesIndexesDataInterval = setInterval(
    () => utils.updateIndustriesIndexesData(),
    LONG_INTERVAL_FREQUENCY
  );

  appStore.updateIndustriesIndexesDataIntervalIsRunning = true;
};

/**
 * Starts the rt spot indexes overview data fetching interval
 */
export const startUpdateRtSpotIndexesDataInterval = () => {
  if (!appStore.updateRtSpotIndexesDataIntervalIsRunning)
    utils.updateRtSpotIndexesData();

  appStore.updateRtSpotIndexesDataInterval = setInterval(
    () => utils.updateRtSpotIndexesData(),
    LONG_INTERVAL_FREQUENCY
  );

  appStore.updateRtSpotIndexesDataIntervalIsRunning = true;
};

/**
 * Toggles the interval on/off
 */
export const toggleIndexSelectedBarInterval = () => {
  const { changeSelectedBarIntervalIsRunning } = appStore;

  if (changeSelectedBarIntervalIsRunning)
    clearInterval(appStore.changeSelectedBarInterval);
  if (!changeSelectedBarIntervalIsRunning) startChangeSelectedBarInterval();

  appStore.changeSelectedBarIntervalIsRunning = !changeSelectedBarIntervalIsRunning;
};

/**
 * Stops the interval and restarts the clock again
 */
export const stopAndRestartSelectedBarInterval = () => {
  if (appStore.changeSelectedBarIntervalIsRunning) {
    clearInterval(appStore.changeSelectedBarInterval);

    appStore.changeSelectedBarIntervalIsRunning = false;

    startChangeSelectedBarInterval();
  }
};

/**
 * Interval for checking if the market has opened yet
 */
export const getIntradayDataIsReadyInterval = () => {
  appStore.getIntradayDataIsReadyInterval = setInterval(
    () => utils.getIntradayDataIsReady(),
    SHORT_INTERVAL_FREQUENCY
  );

  if (appStore.intradayDataIsReady)
    clearInterval(appStore.getIntradayDataIsReadyInterval);
};
