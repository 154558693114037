import React from "react";
import { view } from "@risingstack/react-easy-state";

import appStore from "../../stores/appStore";

import "./ShadedLineChartLegend.css";

const ShadedLineChartLegend = ({ children: { chart, title } }) => {
  const { chartDates } = appStore;

  return (
    <div className="legend">
      <span>{title}</span>
      <span>{chartDates[chart]}</span>
    </div>
  );
};

export default view(ShadedLineChartLegend);
