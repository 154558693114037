import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { AutoSizer } from "react-virtualized";
import { AxisLeft, AxisRight } from "@vx/axis";
import { extent } from "d3";
import { format, parse } from "date-fns";
import * as Grid from "@vx/grid";
import { Group } from "@vx/group";
import { Bar, Line, LinePath } from "@vx/shape";
import { Point } from "@vx/point";
import { Text } from "@vx/text";
import { scaleBand, scaleLinear } from "@vx/scale";

import colors from "../../chartColors.json";
import { yyyyMMdd } from "../../constants";

const ShadedLineChart = ({
  chartData,
  riskData,
  margin,
  shadedAreasIndexes,
  shadeYtd,
  xDataAccessor,
  yDataAccessor,
  xTickFormat,
  yTickFormatter,
}) => (
  <AutoSizer>
    {({ width, height }) => {
      if (!width || !height) return null;

      const chartableWidth = width - margin.left - margin.right;

      const xRange = [margin.left, chartableWidth];
      const yRange = [height - margin.top - margin.bottom, 0];

      const xScale = scaleBand({
        range: xRange,
        domain: chartData.map(xDataAccessor),
      });

      const xScaleRisk = scaleBand({
        range: xRange,
        domain: riskData.map(xDataAccessor),
      });

      const yScale = scaleLinear({
        range: yRange,
        domain: extent(chartData, yDataAccessor),
      });

      const yScaleRisk = scaleLinear({
        range: yRange,
        domain: extent(riskData, yDataAccessor),
      });

      const x = xDataAccessor;
      const y = yDataAccessor;

      const defined = (d) => !Number.isNaN(d.value);
      const axisBottomTopAttr = yScale.range()[0];

      let shadedAreas;

      const shadeWidth = xScale.range()[1] - xScale.range()[0];

      if (shadedAreasIndexes) {
        const shadedAreasIndexesLimit = shadedAreasIndexes.length - 1;

        shadedAreas = shadedAreasIndexes.map((area, index) => {
          const thisIndex = (i = 0) => {
            if (index + i > shadedAreasIndexesLimit) return chartData.length;
            return shadedAreasIndexes[index + i];
          };

          const xShade = (thisIndex() / chartData.length) * shadeWidth;
          const barWidth =
            ((thisIndex(1) - thisIndex()) / chartData.length) * shadeWidth;

          return { x: xShade, barWidth };
        });
      }

      const shadedAreasLimit = shadedAreas.length - 1;
      const now = new Date();
      const dayOfMonth = now.getDate();
      const month = now.toLocaleString("en-us", { month: "short" });
      const year = now.toLocaleString("en-us", { year: "2-digit" });
      const shadeRemainder = shadeYtd ? 0 : 1;
      const textFill = "#cccccc";
      const strokeColor = "rgba(255, 255, 255, 0.2)";
      const axisLeftTickLabelPropsFn = () => ({
        dx: "-0.5em",
        dy: "0.25em",
        fill: textFill,
        fontSize: 10,
        textAnchor: "end",
      });
      const axisRightTickLabelPropsFn = () => ({
        dx: "0.5em",
        dy: "0.25em",
        fill: textFill,
        fontSize: 10,
      });

      return (
        <svg width={width} height={height} fontSize={12}>
          <Group top={margin.top} left={margin.left}>
            {shadedAreasIndexes &&
              shadedAreas.map((shade, i) => {
                const bar = (
                  <Bar
                    key={`shade-${shade.x}-${shade.barWidth}`}
                    width={shade.barWidth}
                    height={axisBottomTopAttr}
                    x={shade.x + margin.left}
                    y={0}
                    fill="#212121"
                    shapeRendering="crispEdges"
                  />
                );

                const { exclude, prefix, suffix } = xTickFormat;
                const { date } = chartData[shadedAreasIndexes[i]];

                let formattedDate;

                if (typeof date === "string") {
                  formattedDate = format(
                    parse(date, yyyyMMdd, new Date()),
                    xTickFormat.dateFormat
                  );
                } else {
                  formattedDate = format(date, xTickFormat.dateFormat);
                }

                const tempTick = formattedDate;

                let tick = tempTick;

                // Only show the last tick for All Data chart if:
                // it's the last tick,
                // it's the same year as today's year,
                // and it's past the 10th day of January
                if (
                  i === shadedAreasLimit &&
                  year === tempTick &&
                  month === "Jan" &&
                  dayOfMonth < 11
                ) {
                  tick = "";
                }

                // Only show the last tick for trailing12 chart if:
                // it's the last tick,
                // it's the same month as today's month,
                // and it's past the 10th day of the month
                if (
                  i === shadedAreasLimit &&
                  month === tempTick &&
                  dayOfMonth < 11
                )
                  tick = "";

                if (i === 0) {
                  // hide the first "Dec" in the trailing12 chart --> TODO: WHY???
                  if (tempTick === "Dec") tick = "";
                  // hide the first tick if it's past the 18th day of the month
                  if (dayOfMonth > 18) tick = "";
                }

                let tickText = null;

                if (!exclude.includes(tick)) {
                  tickText = tick === "" ? tick : `${prefix}${tick}${suffix}`;
                }

                const text = (
                  <Text
                    key={`text-${shade.x}-${shade.barWidth}`}
                    dy="0.5rem"
                    fontSize="10px"
                    fill={textFill}
                    fontFamily="Arial"
                    x={shade.x + margin.left}
                    y={axisBottomTopAttr + 6}
                    textAnchor="middle"
                    verticalAnchor="start"
                  >
                    {tickText}
                  </Text>
                );

                if (i % 2 === shadeRemainder) {
                  return (
                    <Fragment key={`fragment-${shade.x}-${shade.barWidth}`}>
                      {bar}
                      {text}
                    </Fragment>
                  );
                }

                return text;
              })}

            <Line
              from={new Point({ x: margin.left, y: axisBottomTopAttr })}
              to={new Point({ x: chartableWidth, y: axisBottomTopAttr })}
              stroke={strokeColor}
            />

            <Grid.GridRows
              left={margin.left}
              numTicks={4}
              scale={yScale}
              stroke={strokeColor}
              width={chartableWidth - margin.left}
            />

            <LinePath
              data={riskData}
              defined={defined}
              x={(d) => xScaleRisk(x(d))}
              y={(d) => yScaleRisk(y(d))}
              stroke="#cccccc"
              strokeWidth={1}
              strokeMiterlimit={1}
            />

            <LinePath
              data={chartData}
              defined={defined}
              x={(d) => xScale(x(d))}
              y={(d) => yScale(y(d))}
              stroke={colors.blue}
              strokeWidth={2}
              strokeMiterlimit={1}
            />

            <AxisLeft
              left={margin.left}
              numTicks={4}
              scale={yScale}
              stroke={strokeColor}
              tickFormat={yTickFormatter}
              tickLabelProps={axisLeftTickLabelPropsFn}
              tickStroke={strokeColor}
            />

            <AxisRight
              left={chartableWidth}
              numTicks={4}
              scale={yScaleRisk}
              stroke={strokeColor}
              tickFormat={yTickFormatter}
              tickLabelProps={axisRightTickLabelPropsFn}
              tickStroke={strokeColor}
            />
          </Group>
        </svg>
      );
    }}
  </AutoSizer>
);

ShadedLineChart.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.object),
  riskData: PropTypes.arrayOf(PropTypes.object),
  margin: PropTypes.objectOf(PropTypes.number),
  shadedAreasIndexes: PropTypes.arrayOf(PropTypes.number),
  shadeYtd: PropTypes.bool,
  xDataAccessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  yDataAccessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  xTickFormat: PropTypes.shape({
    exclude: PropTypes.arrayOf(PropTypes.string),
    prefix: PropTypes.string,
    dateFormat: PropTypes.string,
    suffix: PropTypes.string,
  }).isRequired,
  yTickFormatter: PropTypes.func,
};

ShadedLineChart.defaultProps = {
  chartData: [],
  riskData: [],
  margin: {},
  shadedAreasIndexes: null,
  shadeYtd: false,
  xDataAccessor: ({ date }) => date,
  yDataAccessor: ({ value }) => value,
  yTickFormatter: (tick) => tick,
};

export default ShadedLineChart;
