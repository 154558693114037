import React from "react";
import PropTypes from "prop-types";
import { view } from "@risingstack/react-easy-state";

import appStore from "../../stores/appStore";

import "./InfoPanel.css";

const InfoPanel = ({ data }) => {
  const { content, id } = data;
  const { visibleInfoPanelIds } = appStore;

  if (!visibleInfoPanelIds.length) return null;
  if (!visibleInfoPanelIds.includes(id)) return null;

  return (
    <div className="info-panel">
      <div className="info-panel-content">{content}</div>
      <div className="info-panel-background" />
    </div>
  );
};

export default view(InfoPanel);

InfoPanel.propTypes = {
  data: PropTypes.shape({ content: PropTypes.string, id: PropTypes.string })
    .isRequired,
};
