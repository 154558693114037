import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { AutoSizer } from "react-virtualized";
import { AxisBottom, AxisLeft } from "@vx/axis";
import { extent } from "d3";
import * as Grid from "@vx/grid";
import { GlyphDot } from "@vx/glyph";
import { Group } from "@vx/group";
import { LinePath } from "@vx/shape";
import { scaleBand, scaleLinear } from "@vx/scale";
import { view } from "@risingstack/react-easy-state";

import colors from "../../../../chartColors.json";

const colorsArray = Object.values(colors).slice(0, 3).reverse();

const TermStructureChart = ({
  chartData,
  margin,
  tickFormat,
  xDataAccessor,
  yDataAccessor,
}) => (
  <AutoSizer>
    {({ width, height }) => {
      if (!width || !height) return null;

      const keys = Object.keys(chartData);
      const allData = keys.reduce((acc, key) => acc.concat(chartData[key]), []);

      const xScale = scaleBand({
        range: [margin.left, width - margin.left - margin.right],
        domain: allData.map(xDataAccessor),
      });

      const yScale = scaleLinear({
        range: [height - margin.top - margin.bottom, 0],
        domain: extent(allData, yDataAccessor),
      });

      const bandwidthOffset = xScale.bandwidth() / 2;
      const x = xDataAccessor;
      const y = yDataAccessor;

      const numTicks = 3;

      const textFill = "#cccccc";
      const strokeColor = "gray";
      const axisLeftTickLabelPropsFn = () => ({
        dx: "-0.5em",
        dy: "0.25em",
        fill: textFill,
        fontSize: 10,
        textAnchor: "end",
      });
      const axisBottomTickLabelPropsFn = () => ({
        dy: "0.25em",
        fill: textFill,
        fontSize: 10,
        textAnchor: "middle",
      });

      return (
        <svg width={width} height={height} fontSize={12}>
          <Group top={margin.top} left={margin.left}>
            <Grid.GridRows
              left={margin.left}
              numTicks={numTicks}
              scale={yScale}
              stroke={strokeColor}
              width={width - margin.left - margin.right}
            />

            <AxisBottom
              scale={xScale}
              top={yScale.range()[0]}
              axisClassName="axis-class"
              labelClassName="axis-label-class"
              tickClassName="tick-label-class"
              stroke={strokeColor}
              tickLabelProps={axisBottomTickLabelPropsFn}
              tickStroke={strokeColor}
              tickFormat={(tick) => {
                if (tick === "0y") return "ttm";

                return tick;
              }}
            />

            <AxisLeft
              scale={yScale}
              left={margin.left}
              stroke={strokeColor}
              tickLabelProps={axisLeftTickLabelPropsFn}
              tickFormat={tickFormat}
              tickStroke={strokeColor}
              numTicks={numTicks}
            />

            {keys.reverse().map((key, index) => {
              const data = chartData[key];

              return (
                <Fragment
                  key={`linepath-and-dot-fragment-${key}-${data.item}-${data.value}`}
                >
                  <LinePath
                    key={`linepath-${key}-${data.item}-${data.value}`}
                    transform={`translate(${bandwidthOffset}, 0)`}
                    data={data}
                    x={(d) => xScale(x(d))}
                    y={(d) => yScale(y(d))}
                    stroke={colorsArray[index]}
                    strokeWidth={2}
                  />

                  {data.map((d) => {
                    const cx = xScale(x(d));
                    const cy = yScale(y(d));

                    return (
                      <GlyphDot
                        cx={cx}
                        cy={cy}
                        r={3}
                        fill={colorsArray[index]}
                        key={`dot-${d.item}-${d.value}`}
                        stroke={colorsArray[index]}
                        strokeWidth={3}
                        transform={`translate(${bandwidthOffset}, 0)`}
                      />
                    );
                  })}
                </Fragment>
              );
            })}
          </Group>
        </svg>
      );
    }}
  </AutoSizer>
);

TermStructureChart.propTypes = {
  chartData: PropTypes.shape({
    current: PropTypes.arrayOf(PropTypes.object),
    month_ago: PropTypes.arrayOf(PropTypes.object),
    quarter_ago: PropTypes.arrayOf(PropTypes.object),
    year_ago: PropTypes.arrayOf(PropTypes.object),
  }),
  margin: PropTypes.objectOf(PropTypes.number),
  tickFormat: PropTypes.func,
  xDataAccessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  yDataAccessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

TermStructureChart.defaultProps = {
  chartData: {},
  margin: {},
  tickFormat: (tick) => `${tick.toFixed(1)}%`,
  xDataAccessor: ({ item }) => item,
  yDataAccessor: ({ value }) => value,
};

export default view(TermStructureChart);
