var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component } from "react";
import { view } from "@risingstack/react-easy-state";
import { formatDistanceToNow } from "date-fns";
import Description from "./Description";
import PlaybackControls from "../../../PlaybackControls";
import Stats from "./Stats";
import { SHORT_INTERVAL_FREQUENCY } from "../../../../constants";
import appStore from "../../../../stores/appStore";
import * as handlers from "../../../../actions/handlers.js";
import * as requests from "../../../../actions/requests";
import * as utils from "../../../../actions/utils";
import "./Information.css";
var Information = /** @class */ (function (_super) {
    __extends(Information, _super);
    function Information() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = { activeTab: "stats", lastUpdated: '', lastUpdatedInterval: -1 };
        _this.handleTabChange = function (e) {
            var target = e.target;
            _this.setState({ activeTab: target.dataset.tab });
        };
        _this.updateLastUpdated = function () { return __awaiter(_this, void 0, void 0, function () {
            var chartDataTimestamps, selectedIndex, needsUpdate, lastUpdated;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        chartDataTimestamps = appStore.chartDataTimestamps, selectedIndex = appStore.selectedIndex;
                        needsUpdate = utils.chartDataNeedsUpdate();
                        if (!needsUpdate) return [3 /*break*/, 3];
                        return [4 /*yield*/, requests.fetchIndexDaily()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, requests.fetchIndexIntraday()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        lastUpdated = chartDataTimestamps[selectedIndex] &&
                            formatDistanceToNow(chartDataTimestamps[selectedIndex], {
                                includeSeconds: true,
                            });
                        this.setState({ lastUpdated: lastUpdated });
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    Information.prototype.componentDidMount = function () {
        var _this = this;
        var lastUpdatedInterval = setInterval(function () { return _this.updateLastUpdated(); }, SHORT_INTERVAL_FREQUENCY);
        this.setState({ lastUpdatedInterval: lastUpdatedInterval });
    };
    Information.prototype.componentWillUnmount = function () {
        var lastUpdatedInterval = this.state.lastUpdatedInterval;
        clearInterval(lastUpdatedInterval);
    };
    Information.prototype.render = function () {
        var _a = this.state, lastUpdated = _a.lastUpdated, activeTab = _a.activeTab;
        var chartDataTimestamps = appStore.chartDataTimestamps, changeSelectedBarIntervalIsRunning = appStore.changeSelectedBarIntervalIsRunning, selectedIndex = appStore.selectedIndex;
        var handleIndexBackButtonClick = handlers.handleIndexBackButtonClick, handleIndexPlayPauseButtonClick = handlers.handleIndexPlayPauseButtonClick, handleIndexForwardButtonClick = handlers.handleIndexForwardButtonClick;
        var handleTabChange = this.handleTabChange;
        var updatedMessage = "";
        if (chartDataTimestamps[selectedIndex] && lastUpdated) {
            updatedMessage = "Updated " + lastUpdated + " ago";
        }
        var description = activeTab === "description";
        var stats = activeTab === "stats";
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "information-header" },
                React.createElement("nav", { className: "nested" },
                    React.createElement("span", { className: stats ? "active" : "", "data-tab": "stats", onClick: handleTabChange }, "Stats"),
                    React.createElement("span", { className: description ? "active" : "", "data-tab": "description", onClick: handleTabChange }, "Description")),
                React.createElement("div", { className: "updated-message" }, updatedMessage),
                React.createElement(PlaybackControls, null, {
                    handleBackButtonClick: handleIndexBackButtonClick,
                    handlePlayPauseButtonClick: handleIndexPlayPauseButtonClick,
                    handleForwardButtonClick: handleIndexForwardButtonClick,
                    id: "index-playback-controls",
                    isPlaying: changeSelectedBarIntervalIsRunning,
                })),
            stats && React.createElement(Stats, null),
            description && React.createElement(Description, null)));
    };
    return Information;
}(Component));
export default view(Information);
