import React from "react";
import { view } from "@risingstack/react-easy-state";

import Block from "../../Block";
import Header from "../../Header";
import InfoPanel from "../../InfoPanel";

import EarningsChart from "./EarningsChart";
import IndustriesIndexes from "./IndustriesIndexes";
import InfoPanelTrigger from "../../InfoPanelTrigger";
import InfoData from "./Information/InfoData";
import IndexSectionContainer from "./IndexSectionContainer";
import RtSpotIndexes from "./RtSpotIndexes";
import TermStructureChartContainer from "./TermStructureChartContainer";

import appStore from "../../../stores/appStore";
import { handleDashboardKeyDown } from "../../../actions/handlers";

import "./DashboardPane.css";

function DashboardPane() {
  React.useEffect(() => {
    appStore.windowWidth = window.innerWidth;

    window.addEventListener("keydown", handleDashboardKeyDown);

    return () => window.removeEventListener("keydown", handleDashboardKeyDown);
  });

  const { earnings, edgar, realAlphaIndexes } = InfoData;

  return (
    <div className="dashboard-tab">
      {/* MANA Quantamental Indexes */}
      <Block>
        {{
          classes: "alpha-indexes",
          content: <IndexSectionContainer />,
          header: <Header>MANA Quantamental Indexes&trade;</Header>,
          info: {
            panel: (
              <InfoPanel
                data={{
                  content: realAlphaIndexes.html,
                  id: "alpha-indexes",
                }}
              />
            ),
            trigger: <InfoPanelTrigger id="alpha-indexes" />,
          },
        }}
      </Block>

      {/* RtSpot Indexes */}
      <Block>
        {{
          classes: "rtspot-indexes",
          content: <RtSpotIndexes />,
          header: <Header>MANA RTSpot Indexes&trade;</Header>,
        }}
      </Block>

      {/* Industries */}
      <Block>
        {{
          classes: "industries",
          content: <IndustriesIndexes />,
          header: <Header>Industries</Header>,
        }}
      </Block>

      {/* MANA Constant Term Structure Indexes */}
      <Block>
        {{
          classes: "term-structure",
          content: <TermStructureChartContainer />,
          header: <Header>MANA Constant Term Structure Indexes&trade;</Header>,
        }}
      </Block>

      {/* Earnings Chart */}
      <Block>
        {{
          classes: "earnings-chart",
          content: <EarningsChart type="earnings" />,
          header: <Header>MANA Earnings Seasonality Index&trade;</Header>,
          info: {
            panel: (
              <InfoPanel
                data={{ content: earnings.html, id: "earnings-chart" }}
              />
            ),
            trigger: <InfoPanelTrigger id="earnings-chart" />,
          },
          title: earnings.items,
        }}
      </Block>

      {/* Edgar Chart */}
      <Block>
        {{
          classes: "edgar-chart",
          content: <EarningsChart type="edgar" />,
          header: <Header>MANA Edgar Seasonality Index&trade;</Header>,
          info: {
            panel: (
              <InfoPanel data={{ content: edgar.html, id: "edgar-chart" }} />
            ),
            trigger: <InfoPanelTrigger id="edgar-chart" />,
          },
          title: edgar.items,
        }}
      </Block>
    </div>
  );
}

export default view(DashboardPane);
