import React from "react";
import PropTypes from "prop-types";
import { view } from "@risingstack/react-easy-state";
import MANALoader from "../../../MANALoader";
import { scaleLinear } from "@vx/scale";

import SparkChart from "../../../SparkChart";
import TargetPlot from "../../../TargetPlot";

import { mutedGreen, mutedRed } from "../../../../colors.json";

import "./IndexesOverview.css";

const IndexesOverview = ({
  currentValues,
  dailyData,
  intradayData,
  headerTitle,
  indexes,
  replacer,
}) => {
  const loader = <MANALoader />;

  if (!currentValues || !dailyData || !intradayData) return loader;

  const allValues = [];
  const currentValuesKeys = Object.keys(currentValues);

  if (!indexes.every((index) => currentValuesKeys.includes(index))) {
    return loader;
  }

  indexes.forEach((item) => allValues.push(currentValues[item].value));

  const minAbs = Math.abs(Math.min(...allValues));
  const maxAbs = Math.abs(Math.max(...allValues));

  const largestValue = minAbs > maxAbs ? minAbs : maxAbs;

  const xScale = scaleLinear({
    range: [5, 17.5, 30],
    domain: [-largestValue, 0, largestValue],
  });

  return (
    <section className="indexes-overview-container">
      <div className="indexes-overview-header">
        <div className="title">{headerTitle}</div>
        <div className="one-day-change">1D %</div>
        <div className="intraday-spark">Intraday</div>
        <div className="twelve-month-spark">12M</div>
      </div>

      {indexes.map((index) => {
        const { value } = currentValues[index];
        const { intraData } = intradayData[index];
        const { trailing12Data } = dailyData[index];
        const indexName = index.replace(replacer, "");
        const intradaySpark = <SparkChart chartData={intraData} />;
        const trailing12Spark = <SparkChart chartData={trailing12Data} />;

        const leftValue = xScale(value) - 5;
        const left = Number.isNaN(leftValue) ? null : leftValue;
        const style = {
          backgroundColor: value >= 0 ? mutedGreen : mutedRed,
          left: `${left}px`,
        };

        let title = indexName;

        const uppercaseFirstLetter = (string) => {
          const newString = string.toLowerCase();

          return newString.charAt(0).toUpperCase() + newString.slice(1);
        };

        if (headerTitle === "RTSpot") {
          if (title.startsWith("FX_")) {
            title = title.replace("FX_", "");
            title = title.replace(/_/g, "");
          } else {
            title = uppercaseFirstLetter(title);
          }
        } else {
          title = title.replace(/_/g, " ");
        }

        const formattedValue = `${Math.round(value * 100).toFixed(2) / 100}%`;

        return (
          <div className="indexes-overview-plots-container" key={index}>
            <div className="title-container">{title}</div>
            {typeof value === "number" ? (
              <TargetPlot style={style} value={formattedValue} />
            ) : (
              loader
            )}
            <div className="intraday-container">
              {intraData.length > 0 ? intradaySpark : loader}
            </div>
            <div className="trailing-twelve-container">
              {trailing12Data.length > 0 ? trailing12Spark : loader}
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default view(IndexesOverview);

IndexesOverview.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  currentValues: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  dailyData: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  intradayData: PropTypes.object,
  headerTitle: PropTypes.string.isRequired,
  indexes: PropTypes.arrayOf(PropTypes.string).isRequired,
  replacer: PropTypes.string.isRequired,
};

IndexesOverview.defaultProps = {
  currentValues: {},
  dailyData: {},
  intradayData: {},
};
