import React from "react";
import { view } from "@risingstack/react-easy-state";
import colors from "../../../../chartColors.json";
import LegendKey from "../../../LegendKey";
import LegendKeysInline from "../../../LegendKeysInline";
function TermStructureLegend() {
    var colorsArray = Object.keys(colors);
    function legendKeys() {
        return ["Current", "1M ago", "1Y ago"].map(function (text, index) {
            return (React.createElement(LegendKey, { key: text }, {
                color: colorsArray[index],
                // inline: true,
                text: text,
            }));
        });
    }
    return React.createElement(LegendKeysInline, null, { legendKeys: legendKeys() });
}
export default view(TermStructureLegend);
