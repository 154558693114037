import React from "react";
import { view } from "@risingstack/react-easy-state";

import InfoData from "../Information/InfoData";

import appStore from "../../../../stores/appStore";

import "./HeatmapAndBarHeaders.css";

const HeatmapAndBarHeaders = () => {
  const { heatmapPeriodHeaders } = appStore;
  const titles = InfoData.realAlphaIndexes.items.heatMapAndBars;

  return (
    <>
      <div className="returns-header-container">
        <div className="returns-header">Returns</div>
        <div className="returns-border" />
      </div>
      <div className="periods-headers-container">
        {heatmapPeriodHeaders &&
          heatmapPeriodHeaders.map((period) => {
            let modifiedPeriod;
            let title;

            if (period === "All") {
              modifiedPeriod = `${period}\n(Ann. %)`;
              title = titles.all;
            } else if (period.length === 4) {
              modifiedPeriod = `${period}\n(%)`;
              title = titles.ytd;
            } else if (period === "Sharpe") {
              modifiedPeriod = "LTD\nSharpe";
              title = titles.ltdSharpe;
            } else {
              modifiedPeriod = `${period}\n(bps)`;
              title = titles.month;
            }

            return (
              <div key={modifiedPeriod} title={title}>
                {modifiedPeriod}
              </div>
            );
          })}

        <div className="hz-bar-header" title={titles.perf}>
          Performance (bps)
        </div>
      </div>
      <div className="grid-gap horizontal not-mobile" />
    </>
  );
};

export default view(HeatmapAndBarHeaders);
