import React, { Component } from "react";
import { view } from "@risingstack/react-easy-state";
import PropTypes from "prop-types";
import { parse } from "date-fns";

import MANALoader from "../../../MANALoader";
import VerticalBarChart from "./VerticalBarChart";
import { yyyyMMdd } from "../../../../constants";
import appStore from "../../../../stores/appStore";
import * as requests from "../../../../actions/requests";

const accessors = {
  dateAccessor: ({ date }) => parse(date, yyyyMMdd, new Date()),
  upAccessor: ({ up }) => up,
  dnAccessor: ({ dn }) => dn,
};

class EarningsChart extends Component {
  async componentDidMount() {
    const { type } = this.props;

    await requests.fetchEarningsData(type);
  }

  render() {
    const { indexesData } = appStore;
    const { type } = this.props;

    if (!indexesData[type]) return <MANALoader />;

    const margin = {
      left: 16,
      right: 0,
      top: 6,
      bottom: 24,
    };

    return (
      <div style={{ height: "100%" }}>
        <VerticalBarChart
          accessors={accessors}
          chartData={indexesData[type]}
          margin={margin}
          type={type}
        />
      </div>
    );
  }
}

export default view(EarningsChart);

EarningsChart.propTypes = {
  type: PropTypes.string.isRequired,
};
