import React from "react";

import { AutoSizer } from "react-virtualized";
import { extent } from "d3";
import { Line, LinePath } from "@vx/shape";
import { Point } from "@vx/point";
import { scaleBand, scaleLinear } from "@vx/scale";

import { manaPurple } from "../../colors.json";

const SparkChart = ({ chartData }) => (
  <AutoSizer>
    {({ width, height }) => {
      if (!width || !height) return null;

      const xRange = [0, width];
      const yRange = [height, 0];

      const xDataAccessor = ({ date }) => date;
      const yDataAccessor = ({ value }) => value;

      const xScale = scaleBand({
        range: xRange,
        domain: chartData.map(xDataAccessor),
      });

      const yScale = scaleLinear({
        range: yRange,
        domain: extent(chartData, yDataAccessor),
      });

      const x = xDataAccessor;
      const y = yDataAccessor;

      const defined = (d) => !Number.isNaN(d.value);
      const zeroLine = yScale(y(chartData[0]));

      return (
        <svg width={width} height={height}>
          <Line
            from={new Point({ x: 0, y: zeroLine })}
            to={new Point({ x: width, y: zeroLine })}
            stroke="#4c4c4c"
          />

          <LinePath
            data={chartData}
            defined={defined}
            x={(d) => xScale(x(d))}
            y={(d) => yScale(y(d))}
            stroke={manaPurple}
            strokeWidth={1}
          />
        </svg>
      );
    }}
  </AutoSizer>
);

export default SparkChart;
