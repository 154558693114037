import React, { Component } from "react";
import { view } from "@risingstack/react-easy-state";

import MANALoader from "../../../MANALoader";
import ShadedLineChart from "../../../ShadedLineChart";
import ShadedLineChartLegend from "../../../ShadedLineChartLegend";
import { URL_BASE } from "../../../../constants";

import appStore from "../../../../stores/appStore";
import * as intervals from "../../../../actions/intervals";
import * as processors from "../../../../actions/processors";
import * as requests from "../../../../actions/requests";
import * as utils from "../../../../actions/utils";

const style = {
  alignItems: "center",
  color: "gray",
  display: "flex",
  height: "100%",
  justifyContent: "space-around",
  width: "100%",
};

class IntradayChart extends Component {
  async componentDidMount() {
    const index = "Risk_LiquidUSRisk";
    const intradayData = await utils.fetchData(
      `${URL_BASE}/indexes/${index}/intraday`
    );

    processors.mungeIndexIntraday(intradayData, index);
    await requests.fetchIndexIntraday();

    utils.getIntradayDataIsReady();
    intervals.getIntradayDataIsReadyInterval();
  }

  componentDidUpdate() {
    const { intradayChartData, selectedIndex } = appStore;
    const chartData = intradayChartData[selectedIndex];
    const needsUpdate = utils.chartDataNeedsUpdate();

    if (!chartData || needsUpdate) requests.fetchIndexIntraday();
  }

  componentWillUnmount() {
    if (appStore.getIntradayDataIsReadyInterval) {
      clearInterval(appStore.getIntradayDataIsReadyInterval);
    }
  }

  render() {
    const { intradayChartData, intradayDataIsReady, selectedIndex } = appStore;

    if (!intradayDataIsReady) {
      return (
        <div style={style}>
          Intraday data will become available at 9:45am ET
        </div>
      );
    }

    const chartData = intradayChartData[selectedIndex];
    const riskData = intradayChartData.Risk_LiquidUSRisk;

    if (!chartData || !chartData.length || !riskData || !riskData.length) {
      return <MANALoader />;
    }

    const margin = {
      left: 24,
      right: 50,
      top: 14,
      bottom: 14,
    };

    const dates = chartData.map(({ date }) => {
      const theDate = new Date(date);

      return theDate.getHours();
    });
    const shadedAreasIndexes = utils.getShadedAreasIndexes(dates);
    const yTickFormatter = (tick) => `${Math.round(+tick * 10000)}bps`;

    return (
      <>
        <ShadedLineChartLegend>
          {{
            chart: "intraday",
            title: "Intraday",
          }}
        </ShadedLineChartLegend>
        <div style={{ height: "calc(100% - 18px)" }}>
          <ShadedLineChart
            chartData={chartData}
            riskData={riskData}
            margin={margin}
            shadedAreasIndexes={shadedAreasIndexes}
            xTickFormat={{
              exclude: ["09", "16"],
              prefix: "",
              dateFormat: "hh",
              suffix: ":00",
            }}
            yTickFormatter={yTickFormatter}
          />
        </div>
      </>
    );
  }
}

export default view(IntradayChart);
