import React from "react";
import { view } from "@risingstack/react-easy-state";
import LegendKey from "./LegendKey";
import "./QuantamentalIndexesLegend.css";
function QuantamentalIndexesLegend(_a) {
    var legendKeys = _a.children.legendKeys;
    return (React.createElement("div", { className: "quant-indexes-legend" },
        legendKeys.map(function (_a) {
            var color = _a.color, text = _a.text;
            return (React.createElement(LegendKey, { key: color }, {
                color: color,
                text: text,
            }));
        }),
        React.createElement("div", { className: "separator" })));
}
export default view(QuantamentalIndexesLegend);
