import React from "react";
import { view } from "@risingstack/react-easy-state";
import MANALoader from "../../../../MANALoader";

import appStore from "../../../../../stores/appStore";

import "./Description.css";

const loader = <MANALoader />;

const Description = () => {
  const { indexesInfo, selectedIndex } = appStore;

  if (!indexesInfo) return loader;

  const data = indexesInfo[selectedIndex];

  if (!data) return loader;

  const { desc } = data;

  const description =
    desc && desc.includes("(tm)") ? desc.replace("(tm)", "™") : "";

  return <div className="stats-description">{description}</div>;
};

export default view(Description);
